import React, { useCallback } from 'react';
import { TableCell, TableSortLabel } from '@mui/material';
import SortDesc from '@mui/icons-material/ArrowDropUp';
import SortAsc from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as Sort } from '../../images/sort.svg';
import { SortOrder } from '../../utils/appConstants';

const HeadCell = ({
  cell, order, orderBy, setOrder, setOrderBy,
}) => {
  const handleSortClick = useCallback(() => {
    let nextSortOrder = SortOrder.asc;
    if (orderBy === cell.id) {
      if (order === SortOrder.asc) nextSortOrder = SortOrder.desc;
      else nextSortOrder = SortOrder.asc;
    }

    setOrder(nextSortOrder);
    setOrderBy(cell.id);
  }, [setOrder, setOrderBy, cell, order, orderBy]);

  const getSortIcon = useCallback(() => {
    if (orderBy === cell.id && order === SortOrder.asc) return SortAsc;
    if (orderBy === cell.id && order === SortOrder.desc) return SortDesc;

    return Sort;
  }, [order, orderBy, cell.id]);

  let content;
  if (cell.sortEnabled) {
    content = (
      <TableSortLabel
        active
        onClick={handleSortClick}
        IconComponent={getSortIcon()}
        classes={{
          root: 'sort-label',
          icon: 'sort-icon',
        }}
        data-testid={order === SortOrder.desc && orderBy === cell.id ? 'sort-desc-label' : 'sort-asc-label'}
      >
        <span>
          {cell.label}
        </span>
      </TableSortLabel>
    );
  } else {
    content = (
      <span className="sort-label">
        {cell.label}
      </span>
    );
  }

  let sortDirection;
  if (orderBy === cell.id) {
    if (order === SortOrder.asc) {
      sortDirection = 'asc';
    } else {
      sortDirection = 'desc';
    }
  } else {
    sortDirection = false;
  }

  return (
    <TableCell
      align={cell.align}
      padding={cell.disablePadding ? 'none' : 'normal'}
      sortDirection={sortDirection}
      style={{ width: cell.width }}
    >
      {content}
    </TableCell>
  );
};

export default HeadCell;
