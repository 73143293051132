import { useState, useMemo } from 'react';

const usePagination = ({ defaultPerPage, defaultPage }) => {
  const [page, setPage] = useState(defaultPage);
  const [perPage, setPerPage] = useState(defaultPerPage);

  return useMemo(() => {
    return {
      page, perPage, setPage, setPerPage,
    };
  }, [page, perPage, setPage, setPerPage]);
};

export default usePagination;
