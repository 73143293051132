import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../dropdown";
import useFetch from "../../hooks/useFetch";
import DomainService from "../../services/domain.service";
import Logo from "../../images/logo.png"
import Dashboard from "../../images/dashboard.svg"
import DashboardSelected from "../../images/dashboard-selected.svg"
import Swagger from "../../images/swagger.svg"
import SwaggerSelected from "../../images/swagger-selected.svg"
import Domains from "../../images/domains.svg"
import LoadingNumber from "../../images/loading-number.svg"
import LoadingNumberSelected from "../../images/loading-number-selected.svg"
import DomainsSelected from "../../images/domains-selected.svg"
import Proxies from "../../images/proxies.svg"
import ProxiesSelected from "../../images/proxies-selected.svg"
import Users from "../../images/users.svg"
import UsersSelected from "../../images/users-selected.svg"
import Vehicles from "../../images/vehicles.svg"
import VehiclesSelected from "../../images/vehicles-selected.svg"
import Drivers from "../../images/driver.svg"
import DriversSelected from "../../images/driver-selected.svg"
import Logout from "../../images/logout.svg"
import Summary from "../../images/summary.svg"
import SummarySelected from "../../images/summary-selected.svg"
import Products from "../../images/products.svg"
import ProductsSelected from "../../images/products-selected.svg"
import ShipperIds from "../../images/shipper-ids.svg"
import ShipperIdsSelected from "../../images/shipper-ids-selected.svg";
import CustomerIds from "../../images/customer-ids.svg"
import CustomerIdsSelected from "../../images/customer-ids-selected.svg";
import PublicCustomerIdMapping from "../../images/public-customer-id-mapping.svg"
import PublicCustomerIdMappingSelected from "../../images/public-customer-id-mapping-selected.svg"
import CrossRefOrders from "../../images/cross-ref-orders.svg"
import CrossRefOrdersSelected from "../../images/cross-ref-orders-selected.svg"
import Orders from "../../images/orders.svg"
import OrdersSelected from "../../images/orders-selected.svg"
import CarrierIdsToTcn from "../../images/carrier-ids-to-tcn.svg"
import CarrierIdsToTcnSelected from "../../images/carrier-ids-to-tcn-selected.svg"
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

const LeftSection = ({ user, signOut, collapse, access, handleActAsDomainIdChange, actAsDomainId }) => {
  const navigate = useNavigate();

  const { data: carrierOptions } = useFetch({
    service: DomainService,
    func: 'getDomains',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order: 'ASC',
      orderBy: 'domain_name',
      page: 1,
      perPage: 1000,
      searchText: ''
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken]
  });

  const selected = () => {
    if (window.location.pathname.includes('dashboard')) return 'dashboard';
    else if (window.location.pathname.includes('swagger')) return 'swagger';
    else if (window.location.pathname.includes('domains')) return 'domains';
    else if (window.location.pathname.includes('proxies')) return 'proxies';
    else if (window.location.pathname.includes('drivers')) return 'drivers';
    else if (window.location.pathname.includes('vehicles')) return 'vehicles';
    else if (window.location.pathname.includes('users')) return 'users';
    else if (window.location.pathname.includes('shipperIds')) return 'shipperIds';
    else if (window.location.pathname.includes('products')) return 'products';
    else if (window.location.pathname.includes('customerIds')) return 'customerIds';
    else if (window.location.pathname.includes('public-customer-ids-mapping')) return 'public-customer-ids-mapping';
    else if (window.location.pathname.includes('carrier-ids-to-tcn')) return 'carrier-ids-to-tcn';
    else if (window.location.pathname.includes('loading-numbers')) return 'loading-numbers';
    else if (window.location.pathname.includes('cross-ref-orders')) return 'cross-ref-orders';
    else if (window.location.pathname.includes('orders-summary')) return 'summary';
    else if (window.location.pathname.includes('orders')) return 'orders';
    else return ''
  }

  const handleNavigation = (navigation) => {
    navigate(navigation)
  }

  const manageDomainsAccess = ['superadmin']
  const manageProxiesAccess = ['superadmin']
  const manageDriversAccess = ['superadmin', 'admin']
  const manageVehiclesAccess = ['superadmin', 'admin']
  const manageOrdersAccess = ['superadmin']
  const manageCrossRefOrdersAccess = ['superadmin', 'admin']
  const manageUsersAccess = ['superadmin', 'admin']
  const manageShipperIdsAccess = ['superadmin', 'admin']
  const manageProductsAccess = ['superadmin', 'admin', 'fulluser', 'limiteduser']
  const manageCustomerIdsAccess = ['superadmin', 'admin']
  const managePublicCustomerIdsMappingAccess = ['superadmin', 'admin']
  const manageCarrierIdsToTcnAccess = ['superadmin', 'admin']
  const manageLoadingNumberAccess = ['superadmin', 'admin']
  const swaggerDashboardAccess = ['superadmin', 'admin', 'fulluser', 'limiteduser']
  const summaryAccess = ['admin']

  const dropdownValue = useMemo(() => {
    const tempOption = (carrierOptions || []).find(item => item._id === actAsDomainId)
    return {
      value: tempOption?._id,
      label: tempOption?.domain_name
    }
  }, [actAsDomainId, JSON.stringify(carrierOptions)])

  return (
    <div className={collapse ? "layout-left layout-left-section-collapsed" : "layout-left layout-left-section"}>
      <div className="header-logo">
        <img src={Logo} alt="Main" />
      </div>
      {
        access &&
        <div className="menus">
          {
            swaggerDashboardAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'dashboard' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/dashboard')}>
              <img src={selected() !== 'dashboard' ? Dashboard : DashboardSelected} alt="Menu" />
              {!collapse && <label>Dashboard</label>}
            </div>
          }

          {
            swaggerDashboardAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'swagger' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/swagger')}>
              <img src={selected() !== 'swagger' ? Swagger : SwaggerSelected} alt="Menu" />
              {!collapse && <label>Swagger</label>}
            </div>
          }

          {
            manageDomainsAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'domains' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/domains')}>
              <img src={selected() !== 'domains' ? Domains : DomainsSelected} alt="Menu" />
              {!collapse && <label>Domains</label>}
            </div>
          }

          {
            manageProxiesAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'proxies' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/proxies')}>
              <img src={selected() !== 'proxies' ? Proxies : ProxiesSelected} alt="Menu" />
              {!collapse && <label>Proxies</label>}
            </div>
          }

          {
            manageUsersAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'users' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/users')}>
              <img src={selected() !== 'users' ? Users : UsersSelected} alt="Menu" />
              {!collapse && <label>Users</label>}
            </div>
          }

          {
            manageDriversAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'drivers' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/drivers')}>
              <img src={selected() !== 'drivers' ? Drivers : DriversSelected} alt="Menu" />
              {!collapse && <label>Drivers</label>}
            </div>
          }

          {
            manageVehiclesAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'vehicles' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/vehicles')}>
              <img src={selected() !== 'vehicles' ? Vehicles : VehiclesSelected} alt="Menu" />
              {!collapse && <label>Vehicles</label>}
            </div>
          }

          {
            manageShipperIdsAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'shipperIds' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/shipperIds')}>
              <img src={selected() !== 'shipperIds' ? ShipperIds : ShipperIdsSelected} alt="Menu" />
              {!collapse && <label>ShipperIds</label>}
            </div>
          }

          {
            manageProductsAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'products' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/products')}>
              <img src={selected() !== 'products' ? Products : ProductsSelected} alt="Menu" />
              {!collapse && <label>Products</label>}
            </div>
          }

          {
            manageCustomerIdsAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'customerIds' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/customerIds')}>
              <img src={selected() !== 'customerIds' ? CustomerIds : CustomerIdsSelected} alt="Menu" />
              {!collapse && <label>CustomerIds</label>}
            </div>
          }

          {
            managePublicCustomerIdsMappingAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'public-customer-ids-mapping' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/public-customer-ids-mapping')}>
              <img src={selected() !== 'public-customer-ids-mapping' ? PublicCustomerIdMapping : PublicCustomerIdMappingSelected} alt="Menu" />
              {!collapse && <label>Public CustomerIds Mapping</label>}
            </div>
          }

          {
            manageCarrierIdsToTcnAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'carrier-ids-to-tcn' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/carrier-ids-to-tcn')}>
              <img src={selected() !== 'carrier-ids-to-tcn' ? CarrierIdsToTcn : CarrierIdsToTcnSelected} alt="Menu" />
              {!collapse && <label>CarrierIds To TCN</label>}
            </div>
          }

          {
            manageLoadingNumberAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'loading-numbers' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/loading-numbers')}>
              <img src={selected() !== 'loading-numbers' ? LoadingNumber : LoadingNumberSelected} alt="Menu" />
              {!collapse && <label>Loading Number</label>}
            </div>
          }

          {
            manageOrdersAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'orders' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/orders')}>
              <img src={selected() !== 'orders' ? Orders : OrdersSelected} alt="Menu" />
              {!collapse && <label>Orders</label>}
            </div>
          }

          {
            summaryAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'summary' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/orders-summary')}>
              <img src={selected() !== 'summary' ? Summary : SummarySelected} alt="Menu" />
              {!collapse && <label>Summary</label>}
            </div>
          }

          {
            manageCrossRefOrdersAccess.includes(user.attributes['custom:role']) && <div className={selected() === 'cross-ref-orders' ? "menu menu-selected" : "menu"} onClick={() => handleNavigation('/home/cross-ref-orders')}>
              <img src={selected() !== 'cross-ref-orders' ? CrossRefOrders : CrossRefOrdersSelected} alt="Menu" />
              {!collapse && <label>Cross Ref Orders</label>}
            </div>
          }
        </div>
      }

      <div className="layout-left-bottom">
        {
          !collapse
            ?
            user.attributes['custom:role'] === 'superadmin' && <div className="form-field-box act-as-main">
              <label>Act As</label>
              <Dropdown
                options={
                  carrierOptions.map(item => {
                    const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ''}`
                    return {
                      value: item._id,
                      label
                    }
                  })
                }
                onChange={(newValue) => handleActAsDomainIdChange({ target: { value: newValue.value } })}
                value={dropdownValue}
                searchable
              />
            </div>
            :
            <div>
              <Tooltip
                title={<div>
                  <label>Act As Domain: {dropdownValue.label}</label>
                </div>}
                arrow
                placement='left'
                classes={{
                  tooltip: 'tooltip',
                }}
              >
                <InfoIcon className='table-info-icon' />
              </Tooltip>
            </div>
        }
        <button className="btn btn-card" onClick={signOut}>
          {!collapse ? "Sign out" : <img src={Logout} alt="Logout" />}
        </button>
      </div>
    </div >
  )
};

export default LeftSection;