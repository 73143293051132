import http from '../httpConfig';

const getShipperIds = ({ token, order, orderBy, page, perPage, carrierIdFilter }) => {
  let url = `/shipperIds?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}`
  if(carrierIdFilter) {
    url += `&carrierId=${carrierIdFilter}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const exportShipperIds = ({ token, carrierId }) => {
  return http.get(`/shipperIds/export?carrierId=${carrierId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const importShipperIds = ({ token, payload }) => {
  return http.post(`/shipperIds/import`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};


const ShipperIdService = {
  getShipperIds,
  exportShipperIds,
  importShipperIds
};

export default ShipperIdService;