import React from 'react';
import clsx from 'clsx';
import { TableRow, TableCell, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import { dateFormat } from "../../utils/methods";
import { ORDER_STATUS } from "../../utils/appConstants";
import _ from "lodash";

const Row = ({ item, headCells, user, onReplyRowClick }) => {
  const tooltipContent = (
    <div className="order-details-tooltip-content-root content-special">
      <div className='content-left'>
        <div className='input-details'>
          <label className='input-details-title'>Order Details</label>
          <div className='input-inner-content'>
            <ul className='order-details-tooltip-content-list'>
              <li className='order-details-tooltip-content-list-item'>
                <p className='order-details-tooltip-content-list-item-root'>
                  <label className='order-details-tooltip-content-list-item-key'>Order Date: </label>
                  <span className='order-details-tooltip-content-list-item-value'>{item.order_date ? dateFormat(item.order_date) : ''}</span>
                </p>
                <p className='order-details-tooltip-content-list-item-root'>
                  <label className='order-details-tooltip-content-list-item-key'>Shipment Date: </label>
                  <span className='order-details-tooltip-content-list-item-value'>{item.shipment_date ? dateFormat(item.shipment_date) : ''}</span>
                </p>
                <p className='order-details-tooltip-content-list-item-root'>
                  <label className='order-details-tooltip-content-list-item-key'>Last Modified: </label>
                  <span className='order-details-tooltip-content-list-item-value'>{item.last_modified ? dateFormat(item.last_modified) : ''}</span>
                </p>
                <p className='order-details-tooltip-content-list-item-root'>
                  <label className='order-details-tooltip-content-list-item-key'>Trailed Id: </label>
                  <span className='order-details-tooltip-content-list-item-value'>{item.metered_load.trailer_id || ''}</span>
                </p>
                <p className='order-details-tooltip-content-list-item-root'>
                  <label className='order-details-tooltip-content-list-item-key'>Vehicle Id: </label>
                  <span className='order-details-tooltip-content-list-item-value'>{item.metered_load.vehicle_id || ''}</span>
                </p>
                <p className='order-details-tooltip-content-list-item-root'>
                  <label className='order-details-tooltip-content-list-item-key'>Update Token: </label>
                  <span className='order-details-tooltip-content-list-item-value'>{item.order_update_token || ''}</span>
                </p>
              </li>
            </ul>
            <div className='order-details-tooltip-content-title'></div>
            <label className='order-details-tooltip-content-title'>Line Items</label>
            <ul className='order-details-tooltip-content-list'>
              {
                item.metered_load && item.metered_load.line_items
                  ?
                  item.metered_load.line_items.map((lineItem, index) => {
                    return (
                      <li className='order-details-tooltip-content-list-item' key={index}>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>Line Number: </label>
                          <span className='order-details-tooltip-content-list-item-value'>{lineItem.line_item_number}</span>
                        </p>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>Product Code: </label>
                          <span className='order-details-tooltip-content-list-item-value'>{lineItem.product_code}</span>
                        </p>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>Product Quantity: </label>
                          <span className='order-details-tooltip-content-list-item-value'>{lineItem.product_quantity}</span>
                        </p>
                      </li>
                    )
                  })
                  : null
              }
            </ul>
          </div>
        </div>
      </div>
      <div className='break-line'></div>
      <div className='content-right'>
        <div className='input-details'>
          <label className='input-details-title'>Output Details</label>
          {
            item?.translated_data
              ?
              (
                <div className='input-inner-content'>
                  <div className='content-main-block'>
                    <label className='order-details-tooltip-content-title'>Driver Id</label>
                    <ul className='order-details-tooltip-content-list'>
                      <li className='order-details-tooltip-content-list-item'>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>From: </label>
                          <span className='order-details-tooltip-content-list-item-value'>{item?.translated_data?.driver_id?.from ?? "-"}</span>
                        </p>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>To: </label>
                          <span className='order-details-tooltip-content-list-item-value'>{item?.translated_data?.driver_id?.to ?? "-"}</span>
                        </p>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>Error: </label>
                          <span className='order-details-tooltip-content-list-item-value error'>{item?.translated_data?.driver_id?.error ?? ""}</span>
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div className='content-main-block'>
                    <label className='order-details-tooltip-content-title'>Shipper Id</label>
                    <ul className='order-details-tooltip-content-list'>
                      <li className='order-details-tooltip-content-list-item'>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>From: </label>
                          <span className='order-details-tooltip-content-list-item-value'>{item?.translated_data?.shipper_id?.from ?? "-"}</span>
                        </p>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>To: </label>
                          <span className='order-details-tooltip-content-list-item-value'>{item?.translated_data?.shipper_id?.to ?? "-"}</span>
                        </p>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>Error: </label>
                          <span className='order-details-tooltip-content-list-item-value error'>{item?.translated_data?.shipper_id?.error ?? ""}</span>
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div className='content-main-block'>
                    <label className='order-details-tooltip-content-title'>Customer Id</label>
                    <ul className='order-details-tooltip-content-list'>
                      <li className='order-details-tooltip-content-list-item'>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>From: </label>
                          <span className='order-details-tooltip-content-list-item-value'>{item?.translated_data?.customer_id?.from ?? "-"}</span>
                        </p>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>To: </label>
                          <span className='order-details-tooltip-content-list-item-value'>{item?.translated_data?.customer_id?.to ?? "-"}</span>
                        </p>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>Error: </label>
                          <span className='order-details-tooltip-content-list-item-value error'>{item?.translated_data?.customer_id?.error ?? ""}</span>
                        </p>
                      </li>
                    </ul>
                  </div>

                  {
                    item?.translated_data?.product_code && item?.translated_data?.product_code.length
                      ?
                      <div className='content-main-block'>
                        <label className='order-details-tooltip-content-title'>Product Codes</label>
                        <ul className='order-details-tooltip-content-list'>
                          {
                            item?.translated_data?.product_code.map((productCode, index) => {
                              return (
                                <li className='order-details-tooltip-content-list-item'>
                                  <p className='order-details-tooltip-content-list-item-root'>
                                    <label className='order-details-tooltip-content-list-item-key'>From: </label>
                                    <span className='order-details-tooltip-content-list-item-value'>{productCode.from || "-"}</span>
                                  </p>
                                  <p className='order-details-tooltip-content-list-item-root'>
                                    <label className='order-details-tooltip-content-list-item-key'>To: </label>
                                    <span className='order-details-tooltip-content-list-item-value'>{productCode.to || "-"}</span>
                                  </p>
                                  <p className='order-details-tooltip-content-list-item-root'>
                                    <label className='order-details-tooltip-content-list-item-key'>Error: </label>
                                    <span className='order-details-tooltip-content-list-item-value error'>{productCode.error || ""}</span>
                                  </p>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      : null
                  }
                </div>
              )
              : <label>No Data Found</label>
          }
        </div>
      </div>
      {
        user && user.attributes['custom:role'] === "superadmin" && item?.terminal_order_details && <>
          <div className='break-line'></div>
          <div className='content-right'>
            <div className='input-details'>
              <label className='input-details-title'>Output JSON</label>
              <div className='input-inner-content'>
                <pre>{JSON.stringify(item.terminal_order_details, null, 2)}</pre>
              </div>
            </div>
          </div>
        </>
      }

    </div>
  )

  const orderStatusTooltipContent = (
    <div><span className='order-details-tooltip-content-status'>Previous Status:</span> {item.previous_order_status}</div>
  )

  return (
    <TableRow className="table-body-row">
      {
        headCells.map((headCell) => {
          if (headCell.id === 'replay') {
            const value = _.get(item, 'order_status');
            return (
              <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                {
                  (
                    value === ORDER_STATUS.tandemError ||
                    value === ORDER_STATUS.tandemAlert ||
                    value === ORDER_STATUS.crossRefError ||
                    value === ORDER_STATUS.holding ||
                    value === ORDER_STATUS.terminalError ||
                    value === ORDER_STATUS.terminalApiRejected || 
                    value === ORDER_STATUS.updateXRefError
                  ) ?
                    <button className="btn warning retry-btn" onClick={() => onReplyRowClick(item._id)}>
                      Replay
                    </button>
                    :
                    ""
                }

              </TableCell>
            );
          }

          if (headCell.id === 'created_date' || headCell.id === 'order_update_token_expired_at' || headCell.id === 'metered_load.effective_date') {
            const value = _.get(item, headCell.accessor);
            return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={value ? dateFormat(value) : ''} />);
          }

          if (headCell.id === 'order_status') {
            const value = _.get(item, headCell.accessor);
            return (
              <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                <div className='status-chip'>
                  <label
                    className={
                      clsx(
                        'label-text',
                        !value && 'primary',
                        value === ORDER_STATUS.new && 'complementory',
                        value === ORDER_STATUS.processing && 'processing',
                        value === ORDER_STATUS.holding && 'holding',
                        value === ORDER_STATUS.holdingExpDate && 'holdingExpDate',
                        value === ORDER_STATUS.completed && 'completed',
                        value === ORDER_STATUS.success && 'success',
                        value === ORDER_STATUS.terminalAccepted && 'success',
                        value === ORDER_STATUS.error && 'error',
                        value === ORDER_STATUS.tandemError && 'error',
                        value === ORDER_STATUS.tandemAlert && 'error',
                        value === ORDER_STATUS.crossRefError && 'error',
                        value === ORDER_STATUS.updateXRefError && 'error',
                        value === ORDER_STATUS.terminalError && 'error',
                        value === ORDER_STATUS.terminalApiRejected && 'error',
                        value === ORDER_STATUS.expired && 'expired',
                        value === (ORDER_STATUS.cancelled || ORDER_STATUS.cancelling) && ''
                      )
                    }
                  >{value || "No Status"}</label>
                  {
                    (value === ORDER_STATUS.error || value === ORDER_STATUS.tandemError || value === ORDER_STATUS.tandemAlert || value === ORDER_STATUS.terminalError || value === ORDER_STATUS.terminalApiRejected) &&
                    <Tooltip
                      title={item.processing_failing_reason}
                      arrow
                      classes={{
                        tooltip: 'tooltip',
                      }}
                    >
                      <InfoIcon className='table-info-icon' />
                    </Tooltip>
                  }
                  {
                    (value === ORDER_STATUS.expired) && item.previous_order_status &&
                    <Tooltip
                      title={orderStatusTooltipContent}
                      arrow
                      classes={{
                        tooltip: 'tooltip',
                      }}
                    >
                      <InfoIcon className='table-info-icon' />
                    </Tooltip>
                  }
                </div>
              </TableCell>
            )
          }

          if (headCell.id === 'details') {
            return (
              <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                <Tooltip
                  title={tooltipContent}
                  arrow
                  placement='left'
                  classes={{
                    tooltip: 'tooltip',
                  }}
                >
                  <InfoIcon className='table-info-icon' />
                </Tooltip>

              </TableCell>
            )
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={_.get(item, headCell.accessor)} />);
        })
      }
    </TableRow>
  );
};

export default Row;
