import React from 'react';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import { TableRow, TableCell, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import _ from "lodash";

const Row = ({ item, headCells}) => {
  const tooltipContent = (infoObj) => {
    return (
      <div className="order-details-tooltip-content-root">
        <label className='order-details-tooltip-content-title'>Compartments Details</label>

        <ul className='order-details-tooltip-content-list'>
          {
            infoObj.map((infoItem, index) => {
              return (
                <li className='order-details-tooltip-content-list-item' key={index}>
                  <p className='order-details-tooltip-content-list-item-root'>
                    <label className='order-details-tooltip-content-list-item-key'>Item Number: </label>
                    <span className='order-details-tooltip-content-list-item-value'>{infoItem.number}</span>
                  </p>

                  <p className='order-details-tooltip-content-list-item-root'>
                    <label className='order-details-tooltip-content-list-item-key'>Capacity: </label>
                    <span className='order-details-tooltip-content-list-item-value'>{infoItem.capacity}</span>
                  </p>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }

  return (
    <TableRow className="table-body-row">
      {
        headCells.map((headCell) => {
          let rowText = _.get(item, headCell.accessor)
          
          if (headCell.id === 'compartments') {
            rowText = item.compartments.length;
            const infoObj = item.compartments;

            return (
              <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                {
                  infoObj && infoObj.length
                    ?
                    <Tooltip
                      title={tooltipContent(infoObj)}
                      arrow
                      placement='left'
                      classes={{
                        tooltip: 'tooltip',
                      }}
                    >
                      <div className='compartment-row-div'>
                        {rowText}
                        <InfoIcon className='table-info-icon' />
                      </div>
                    </Tooltip>
                    : '-'
                }
              </TableCell>
            )
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={rowText} />);
        })
      }
    </TableRow>
  );
};

export default Row;
