import React from 'react';
import { TableRow, TableCell, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import _ from "lodash";

const Row = ({ item, headCells}) => {
  return (
    <TableRow className="table-body-row">
      {
        headCells.map((headCell) => {
          let rowText = _.get(item, headCell.accessor)

          // const carrierTooltipContent = (
          //   <div className="order-details-tooltip-content-root">
          //     <label className='order-details-tooltip-content-title'>Carrier Details</label>
          //     <ul className='order-details-tooltip-content-list'>
          //       {
          //         item.carriers.map((carrier, index) => {
          //           return (
          //             <li className='order-details-tooltip-content-list-item' key={index}>
          //               <p className='order-details-tooltip-content-list-item-root'>
          //                 <label className='order-details-tooltip-content-list-item-key'>{carrier.carrier_alias || '-'}: ({carrier.domain_name})</label>
          //               </p>
          //             </li>
          //           )
          //         })
          //       }
          //     </ul>
          //   </div>
          // )

          const terminalTooltipContent = (
            <div className="order-details-tooltip-content-root">
              <label className='order-details-tooltip-content-title'>Terminal Details</label>
              <ul className='order-details-tooltip-content-list'>
                {
                  item.terminals.map((terminal, index) => {
                    return (
                      <li className='order-details-tooltip-content-list-item' key={index}>
                        <p className='order-details-tooltip-content-list-item-root'>
                          <label className='order-details-tooltip-content-list-item-key'>{terminal.terminal.control_number}: ({terminal.driver_id})</label>
                        </p>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          )
          
          // if (headCell.id === 'carriers') {
          //   return (
          //     <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
          //       <Tooltip
          //         title={carrierTooltipContent}
          //         arrow
          //         placement='left'
          //         classes={{
          //           tooltip: 'tooltip',
          //         }}
          //       >
          //         <InfoIcon className='table-info-icon' />
          //       </Tooltip>
          //     </TableCell>
          //   )
          // }

          if (headCell.id === 'terminals') {
            return (
              <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                <Tooltip
                  title={terminalTooltipContent}
                  arrow
                  placement='left'
                  classes={{
                    tooltip: 'tooltip',
                  }}
                >
                  <InfoIcon className='table-info-icon' />
                </Tooltip>
              </TableCell>
            )
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={rowText} />);
        })
      }
    </TableRow>
  );
};

export default Row;
