import React, { useCallback, useMemo, useState, useEffect } from "react";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@mui/material';
import AddEditVehicle from "../components/popup";
import ImportModal from "../components/popup";
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import Spinner from "../components/circularProgress";
import DomainService from "../services/domain.service";
import UserService from "../services/user.service";
import VehicleService from "../services/vehicle.service";
import Table from "../components/Table";
import VehicleRow from "../components/manageVehicles/row";
import Dropdown from "../components/dropdown";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder, MAX_FILE_UPLOAD_SIZE } from "../utils/appConstants";

const vehicleTypeOptions = [{
  label: 'Truck',
  value: 'truck'
}, {
  label: 'Trailer',
  value: 'trailer'
}]

const ManageVehicles = ({ user, actAsDomainIdMemoised, loggedInUser }) => {
  // const [addEditVehicleLoading, setAddEditVehicleLoading] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [loggedInUser, setLoggedInUser] = useState({})
  const modalTitle = 'Import'
  const [open, setOpen] = useState(false);
  const [fileKey, setFileKey] = useState("");
  const [vehicleAdded, setVehicleAdded] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [reload, setReload] = useState(true);
  // const [addVehicleDetails, setAddVehicleDetails] = useState({
  //   carrierId: '',
  //   vehicles: []
  // })
  const [carrierIdFilter, setCarrierIdFilter] = useState('all')

  // const openModal = () => {
  //   setOpen(true);
  // }

  // const closeModal = () => {
  //   setOpen(false);
  //   setAddVehicleDetails({
  //     carrierId: '',
  //     vehicles: []
  //   })
  // }

  // const onVehiclesByCarrierFetched = (response) => {
  //   const tempVehicles = [];
  //   if (response.data.data && response.data.data.length) {
  //     response.data.data.map(vehicle => {
  //       tempVehicles.push({
  //         _id: vehicle._id,
  //         uniqueId: uuid(),
  //         vehicleId: vehicle.vehicle_id,
  //         vehicleType: vehicle.vehicle_type,
  //         compartments: vehicle.compartments.map(compartment => {
  //           return {
  //             ...compartment,
  //             uniqueId: uuid(),
  //           }
  //         })
  //       })
  //     })
  //   } else {
  //     tempVehicles.push({
  //       uniqueId: uuid(),
  //       vehicleId: '',
  //       vehicleType: '',
  //       compartments: [{
  //         uniqueId: uuid(),
  //         number: 1,
  //         capacity: 1000
  //       }]
  //     })
  //   }

  //   // setAddVehicleDetails(item => {
  //   //   return {
  //   //     ...item,
  //   //     vehicles: tempVehicles
  //   //   }
  //   // })
  // }

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
    setFileKey('');
  }

  const {
    order, orderBy, setOrder, setOrderBy,
  } = useSorting({ defaultOrder: SortOrder.asc, defaultOrderBy: 'vehicle_id' });
  const {
    page, perPage, setPage, setPerPage,
  } = usePagination({ defaultPage: 1, defaultPerPage: 10 });

  const { data: vehicles, total: total, loading: vehiclesLoading } = useFetch({
    service: VehicleService,
    func: 'getVehicles',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order, orderBy, page, perPage,
      carrierIdFilter: actAsDomainIdMemoised || ((user.attributes['custom:role'] === "superadmin" || loggedInUser?.domain?.isProxyDomain) ? carrierIdFilter : loggedInUser.domain_id)
    },
    dependency: [reload, user?.signInUserSession?.accessToken?.jwtToken, vehicleAdded, order, orderBy, page, perPage, carrierIdFilter, loggedInUser.domain_id, user.attributes['custom:role'], actAsDomainIdMemoised],
    onSuccess: () => setVehicleAdded(false),
    skip: user.attributes['custom:role'] !== "superadmin" && !loggedInUser.domain_id
  });

  // const getUsers = useCallback((token) => {
  //   UserService.getLoggedInUser(token)
  //     .then(res => {
  //       if(res.data.data) { 
  //         const userObj = res.data.data;
  //         setLoggedInUser(userObj)
  //       }
  //     })
  //     .catch(() => {
  //     })

  // }, [user?.signInUserSession?.accessToken?.jwtToken, order, orderBy, page, perPage])

  // useEffect(() => {
  //   getUsers(user?.signInUserSession?.accessToken?.jwtToken)
  // }, [user?.signInUserSession?.accessToken?.jwtToken])

  // const { loading: vehiclesByCarrierLoading } = useFetch({
  //   service: VehicleService,
  //   func: 'getVehiclesByCarrier',
  //   payload: {
  //     token: user?.signInUserSession?.accessToken?.jwtToken,
  //     carrierId: addVehicleDetails.carrierId
  //   },
  //   dependency: [user?.signInUserSession?.accessToken?.jwtToken, addVehicleDetails.carrierId],
  //   skip: !addVehicleDetails.carrierId,
  //   // onSuccess: onVehiclesByCarrierFetched
  // });

  // const addEditVehicle = useCallback(() => {
  //   setAddEditVehicleLoading(true);
  //   VehicleService.addVehicle(user?.signInUserSession?.accessToken?.jwtToken, addVehicleDetails)
  //     .then(() => {
  //       setAddEditVehicleLoading(false)
  //       setVehicleAdded(true)
  //       closeModal()
  //     })
  // }, [
  //   user?.signInUserSession?.accessToken?.jwtToken,
  //   JSON.stringify(addVehicleDetails)
  // ])

  const { data: carrierOptions } = useFetch({
    service: DomainService,
    func: 'getDomains',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order: 'ASC',
      orderBy: 'domain_name',
      page: 1,
      perPage: 1000,
      searchText: ''
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken]
  });

  // const handleAddMoreVehicle = useCallback((e) => {
  //   const tempVehicles = addVehicleDetails.vehicles;
  //   tempVehicles.push({
  //     uniqueId: uuid(),
  //     vehicleId: '',
  //     vehicleType: '',
  //     compartments: [{
  //       uniqueId: uuid(),
  //       number: 1,
  //       capacity: 1000
  //     }]
  //   })
  //   setAddVehicleDetails({
  //     carrierId: addVehicleDetails.carrierId,
  //     vehicles: tempVehicles
  //   })
  // }, [JSON.stringify(addVehicleDetails)])

  // const handleAddMoreCompartments = useCallback((vehicleUniqueId) => {
  //   const tempVehicles = addVehicleDetails.vehicles.map(item => {
  //     if (item.uniqueId === vehicleUniqueId) {
  //       item.compartments = [...item.compartments, { uniqueId: uuid(), number: item.compartments.length + 1, capacity: 1000 }]
  //       return item
  //     } else {
  //       return item
  //     }
  //   });
  //   setAddVehicleDetails({
  //     carrierId: addVehicleDetails.carrierId,
  //     vehicles: tempVehicles
  //   })
  // }, [JSON.stringify(addVehicleDetails)])

  // const handleCarrierIdChange = (event) => {
  //   setAddVehicleDetails(item => {
  //     return {
  //       ...item,
  //       carrierId: event.target.value,
  //     }
  //   })
  // }

  // const handleVehicleDetailsChange = useCallback((event, uniqueId, name) => {
  //   const tempVehicles = addVehicleDetails.vehicles.map(item => {
  //     if (item.uniqueId === uniqueId) {
  //       return {
  //         ...item,
  //         [name]: event.target.value
  //       }
  //     } else {
  //       return item
  //     }
  //   });
  //   setAddVehicleDetails(details => {
  //     return {
  //       ...details,
  //       vehicles: tempVehicles
  //     }
  //   })
  // }, [JSON.stringify(addVehicleDetails)])

  // const handleCompartmentDetailsChange = useCallback((event, vehicleUniqueId, compartmentUniqueId, name) => {
  //   const numberRegex = /[^0-9]/
  //   if (event.target.value && numberRegex.test(event.target.value.trim())) {
  //     return;
  //   }

  //   const tempVehicles = addVehicleDetails.vehicles.map(item => {
  //     if (item.uniqueId === vehicleUniqueId) {
  //       item.compartments = item.compartments.map(compartmentItem => {
  //         if (compartmentItem.uniqueId === compartmentUniqueId) {
  //           return {
  //             ...compartmentItem,
  //             [name]: event.target.value
  //           }
  //         } else {
  //           return compartmentItem
  //         }
  //       })
  //       return item
  //     } else {
  //       return item
  //     }
  //   });
  //   setAddVehicleDetails(details => {
  //     return {
  //       ...details,
  //       vehicles: tempVehicles
  //     }
  //   })
  // }, [JSON.stringify(addVehicleDetails)])

  // const addEditModalTitle = useMemo(() => {
  //   return "Add Vehicle"
  // }, []);

  // const addEditModalContent = useMemo(() => {
  //   return (
  //     <div className="add-vehicle">
  //       <div className="vehicle-details">
  //         <div className="form-field-box">
  //           <label>Carrier <span className="required">*</span></label>
  //           <Dropdown
  //             options={
  //               carrierOptions.map(item => {
  //                 const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ''}`
  //                 return {
  //                   value: item._id,
  //                   label
  //                 }
  //               })
  //             }
  //             onChange={(newValue) => handleCarrierIdChange({ target: { value: newValue.value } })}
  //             searchable
  //           />
  //         </div>
  //         {
  //           addVehicleDetails.carrierId && (
  //             <div className="vehicles-section">
  //               <div className="vehicles-section-title">
  //                 <label>Vehicles</label>
  //                 <Tooltip
  //                   title="Add more rows below"
  //                   arrow
  //                   classes={{
  //                     tooltip: 'tooltip',
  //                   }}
  //                 >
  //                   <AddBoxRoundedIcon className="add-more-vehicles" onClick={handleAddMoreVehicle} />
  //                 </Tooltip>
  //               </div>
  //               {
  //                 vehiclesByCarrierLoading
  //                   ?
  //                   <Spinner size={22} />
  //                   :
  //                   addVehicleDetails.vehicles.map(vehicleItem => {
  //                     return (
  //                       <div className="vehicle-item-section" key={vehicleItem.uniqueId}>
  //                         <div className="vehicle-item-basic-details">
  //                           <div className="form-field-box">
  //                             <label>Vehicle Id <span className="required">*</span></label>
  //                             <input value={vehicleItem.vehicleId} placeholder="Enter Vehicle Id" onChange={(e) => handleVehicleDetailsChange(e, vehicleItem.uniqueId, 'vehicleId')} />
  //                           </div>
  //                           <div className="form-field-box">
  //                             <label>Vehicle Type <span className="required">*</span></label>
  //                             <Dropdown
  //                               options={vehicleTypeOptions}
  //                               onChange={(newValue) => handleVehicleDetailsChange({ target: { value: newValue.value } }, vehicleItem.uniqueId, 'vehicleType')}
  //                               value={vehicleTypeOptions.find(item => item.value === vehicleItem.vehicleType)}
  //                             />
  //                           </div>
  //                         </div>
  //                         <div className="vehicle-item-compartment-details">
  //                           <div className="vehicle-item-compartment-details-title">
  //                             <label>Compartments</label>
  //                             <Tooltip
  //                               title="Add more rows below"
  //                               arrow
  //                               classes={{
  //                                 tooltip: 'tooltip',
  //                               }}
  //                             >
  //                               <AddBoxRoundedIcon className="add-more-compartments" onClick={() => handleAddMoreCompartments(vehicleItem.uniqueId)} />
  //                             </Tooltip>
  //                           </div>
  //                           {
  //                             vehicleItem.compartments.map(compartmentItem => {
  //                               return (
  //                                 <div className="compartment-main" key={compartmentItem.uniqueId}>
  //                                   <div className="form-field-box">
  //                                     <label>Compartment Number <span className="required">*</span></label>
  //                                     <input value={compartmentItem.number} placeholder="Enter Compartment Number" onChange={(e) => handleCompartmentDetailsChange(e, vehicleItem.uniqueId, compartmentItem.uniqueId, 'number')} />
  //                                   </div>
  //                                   <div className="form-field-box">
  //                                     <label>Capacity (In Gallons) <span className="required">*</span></label>
  //                                     <input value={compartmentItem.capacity} placeholder="Enter Capacity" onChange={(e) => handleCompartmentDetailsChange(e, vehicleItem.uniqueId, compartmentItem.uniqueId, 'capacity')} />
  //                                   </div>
  //                                 </div>
  //                               )
  //                             })
  //                           }
  //                         </div>
  //                       </div>
  //                     )
  //                   })
  //               }

  //             </div>
  //           )
  //         }
  //       </div>
  //     </div>
  //   )
  // }, [
  //   JSON.stringify(carrierOptions),
  //   JSON.stringify(addVehicleDetails),
  //   vehiclesByCarrierLoading
  // ])

  // const btnDisabled = useMemo(() => {
  //   if (!addVehicleDetails.carrierId) {
  //     return true
  //   }

  //   if (!addVehicleDetails.vehicles || !addVehicleDetails.vehicles.length) {
  //     return true
  //   }

  //   if (!addVehicleDetails.vehicles.every(item => item.vehicleId && item.vehicleType)) {
  //     return true
  //   }

  //   let isAnyCompartmentEmpty = false;
  //   addVehicleDetails.vehicles.forEach(vehicleItem => {
  //     vehicleItem.compartments.forEach(compartmentItem => {
  //       if (!compartmentItem.number || !compartmentItem.capacity) {
  //         isAnyCompartmentEmpty = true
  //       }
  //     })
  //   })

  //   if (isAnyCompartmentEmpty) {
  //     return true
  //   }

  //   return false;
  // }, [JSON.stringify(addVehicleDetails)])

  // const addEditModalAction = useMemo(() => {
  //   let btnContent;

  //   if (addEditVehicleLoading) {
  //     btnContent = <Spinner size={22} color="white" />
  //   } else {
  //     btnContent = "Add"
  //   }

  //   return (
  //     <div className="add-domain-btn-div">
  //       <button className={`btn add-domain-btn ${btnDisabled && 'disabled'}`} disabled={btnDisabled} onClick={addEditVehicle}>
  //         {btnContent}
  //       </button>
  //     </div>
  //   )
  // }, [btnDisabled, addEditVehicleLoading, addEditVehicle])


  const headCells = [{
    id: 'carrier_id',
    accessor: 'domainDetails.carrier_alias',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Carrier Alias",
    width: "30%",
  }, {
    id: 'vehicle_id',
    accessor: 'vehicle_id',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Vehicle Id",
    width: "30%",
  }, {
    id: 'vehicle_type',
    accessor: 'vehicle_type',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Vehicle Type",
    width: "20%",
  }, {
    id: 'compartments',
    accessor: 'compartments',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Total Compartments",
    width: "20%",
  }]

  const handleCarrierFilterChange = (event) => {
    setCarrierIdFilter(event.target.value)
    setPage(1)
  }

  const dropdownValue = useMemo(() => {
    if (carrierIdFilter === 'all') {
      return {
        value: 'all',
        label: 'All'
      }
    } else {
      const tempOption = carrierOptions.find(item => item._id === carrierIdFilter)
      const label = `${tempOption.domain_name} ${tempOption.carrier_alias ? `(${tempOption.carrier_alias})` : ''}`
      return {
        value: tempOption._id,
        label: label
      }
    }
  }, [carrierIdFilter])

  const exportVehicles = useCallback(() => {
    setExportLoading(true)
    VehicleService.exportVehicles({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      carrierId: dropdownValue.value
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const exportLinkElement = document.createElement('a');

        exportLinkElement.hidden = true;
        exportLinkElement.download = `${dropdownValue.label}.csv`;
        exportLinkElement.href = url;
        exportLinkElement.text = "downloading...";

        document.body.appendChild(exportLinkElement);
        exportLinkElement.click();
        setExportLoading(false)
        exportLinkElement.remove();
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong", { icon: false });
        setExportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, dropdownValue])

  const importVehicles = useCallback((file) => {
    setImportLoading(true);
    // const formBody = new FormData();
    // formBody.append('file', file);
    // formBody.append('domainId', dropdownValue.value);
    VehicleService.importVehicles({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      payload: {
        domainId: dropdownValue.value,
        fileKey: fileKey
      }
    })
      .then(response => {
        toast.success("File imported successfully.", { icon: false });
        setImportLoading(false);
        setReload(prev => !prev)
        setPage(1)
        closeModal()
      })
      .catch((err) => {
        toast.error(err.response.data.message || "Something went wrong", { icon: false });
        setImportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, dropdownValue, fileKey])

  // const handleFileDrop = (acceptedFiles, fileRejections) => {
  //   if (fileRejections && fileRejections.length > 0) {
  //     fileRejections.forEach((file) => {
  //       file.errors.forEach((err) => {
  //         if (err.code === 'file-too-large') {
  //           toast.error("Imported file is too large", { icon: false });
  //         }
  //       });
  //     });
  //   }

  //   if (acceptedFiles.length > 0) {
  //     importVehicles(acceptedFiles[0])
  //   }
  // };

  // const {
  //   open,
  // } = useDropzone({
  //   noClick: false,
  //   noKeyboard: true,
  //   maxFiles: 1,
  //   accept: {
  //     'text/csv': ['.csv'],
  //   },
  //   maxSize: MAX_FILE_UPLOAD_SIZE,
  //   onDrop: handleFileDrop,
  // });

  const handleFileKeyChange = (e) => {
    setFileKey(e.target.value);
  }

  const modalContent = useMemo(() => {
    return (
      <div className="add-domain">
        <div className="form-field-box">
          <label>S3 File Key</label>
          <input value={fileKey} onChange={handleFileKeyChange} placeholder="S3 File Key" />
          <label className="d-error">{!fileKey.trim() && 'Please Enter S3 File Key'}</label>
        </div>
      </div>
    )
  }, [fileKey])

  const modalAction = useMemo(() => {
    let btnContent;

    if (importLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      btnContent = "Import"
    }

    return (
      <div className="add-domain-btn-div">
        <button className={`btn add-domain-btn ${!fileKey && 'disabled'}`} disabled={!fileKey} onClick={importVehicles}>
          {btnContent}
        </button>
      </div>
    )
  }, [fileKey, importLoading])

  useEffect(() => {
    setPage(1)
  }, [actAsDomainIdMemoised])

  return (
    <div className="root">
      <div className="table-layout">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Vehicles</h3>

          {
            ((user.attributes['custom:role'] === "superadmin" || loggedInUser?.domain?.isProxyDomain) && !actAsDomainIdMemoised) && <div className="filter-box">
              <Dropdown
                options={
                  [
                    {
                      value: 'all',
                      label: 'All'
                    },
                    ...carrierOptions.map(item => {
                      const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ''}`
                      return {
                        value: item._id,
                        label
                      }
                    })
                  ]
                }
                value={dropdownValue}
                onChange={(newValue) => handleCarrierFilterChange({ target: { value: newValue.value } })}
                searchable
                className='carrier-filter'
              />
              {
                user.attributes['custom:role'] === "superadmin" && <>
                  <button className={`btn`} onClick={openModal}>Import
                    {/* {
                  importLoading ? <Spinner size={22} color="white" /> : 'Import'
                } */}
                  </button>
                  <button className={`btn`} onClick={exportVehicles}>
                    {
                      exportLoading ? <Spinner size={22} color="white" /> : 'Export'
                    }
                  </button>
                </>
              }
            </div>
          }
          {/* <button className="btn add-domain-btn" onClick={openModal}>Add Vehicle</button> */}
        </div>
        <div className="domains-table">
          <Table
            data={vehicles}
            loading={vehiclesLoading}
            total={total}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={VehicleRow}
            // rowProps={{ handleEdit, handleDelete }}
            noDataFoundMessage={`No Vehicles Found`}
          />
        </div>
      </div>
      <ImportModal
        open={open}
        title={modalTitle}
        content={modalContent}
        action={modalAction}
        onClose={closeModal}
      />
      {/* <AddEditVehicle
        open={open}
        title={addEditModalTitle}
        content={addEditModalContent}
        action={addEditModalAction}
        paperClassName={"add-vehicle-paper"}
        maxWidth={'lg'}
        onClose={closeModal}
      /> */}
    </div>
  )
};

export default ManageVehicles;