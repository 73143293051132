import React, { useCallback, useMemo, useState, useEffect, useRef } from "react";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@mui/material';
import AddEditDriver from "../components/popup";
import ImportModal from "../components/popup";
import Spinner from "../components/circularProgress";
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import DomainService from "../services/domain.service";
import DriverService from "../services/driver.service";
import UserService from "../services/user.service";
import TerminalService from "../services/terminal.service";
import Table from "../components/Table";
import DriverRow from "../components/manageDrivers/row";
import AutoComplete from "../components/autocomplete";
import Dropdown from "../components/dropdown";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder, MAX_FILE_UPLOAD_SIZE } from "../utils/appConstants";
import { capitalizeFirstLetter } from "../utils/methods";

const ManageDrivers = ({ user, actAsDomainIdMemoised, loggedInUser }) => {
  // const uniqueId = uuid();
  // const [addEditDriverLoading, setAddEditDriverLoading] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [loggedInUser, setLoggedInUser] = useState({})
  const modalTitle = 'Import'
  const [open, setOpen] = useState(false);
  const [fileKey, setFileKey] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [driverAdded, setDriverAdded] = useState(false);
  const [reload, setReload] = useState(true);
  // const [carriersInputValue, setCarriersInputValue] = useState("");
  const [carrierIdFilter, setCarrierIdFilter] = useState('all')
  // const [driverDetails, setDriverDetails] = useState({
  //   driverName: '',
  //   licenceNumber: '',
  //   carrierIds: [],
  //   terminals: {
  //     [uniqueId]: {
  //       terminal_control_number: '',
  //       driver_id: '',
  //       active: false,
  //       renewal_date: ''
  //     }
  //   }
  // })

  // const openModal = () => {
  //   setOpen(true);
  // }

  // const closeModal = () => {
  //   setOpen(false);
  //   setDriverDetails({
  //     driverName: '',
  //     licenceNumber: '',
  //     carrierIds: [],
  //     terminals: [{
  //       terminal_control_number: '',
  //       driver_id: '',
  //       active: false,
  //       renewal_date: ''
  //     }]
  //   })
  //   setCarriersInputValue("")
  // }

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
    setFileKey('');
  }

  const {
    order, orderBy, setOrder, setOrderBy,
  } = useSorting({ defaultOrder: SortOrder.asc, defaultOrderBy: 'driver_name' });
  const {
    page, perPage, setPage, setPerPage,
  } = usePagination({ defaultPage: 1, defaultPerPage: 10 });

  const { data: driversRaw, total: total, loading: driversLoading } = useFetch({
    service: DriverService,
    func: 'getDrivers',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order, orderBy, page, perPage,
      carrierIdFilter: actAsDomainIdMemoised || ((user.attributes['custom:role'] === "superadmin" || loggedInUser?.domain?.isProxyDomain) ? carrierIdFilter : loggedInUser.domain_id)
    },
    dependency: [reload, user?.signInUserSession?.accessToken?.jwtToken, driverAdded, order, orderBy, page, perPage, carrierIdFilter, loggedInUser.domain_id, user.attributes['custom:role'], actAsDomainIdMemoised],
    onSuccess: () => setDriverAdded(false),
    skip: user.attributes['custom:role'] !== "superadmin" && !loggedInUser.domain_id
  });

  const transformDriverData = (data) => {
    return data.map(driver => ({
      ...driver,
      driver_name: capitalizeFirstLetter(driver?.driver_name),
    }));
  };
  const drivers = useMemo(() => transformDriverData(driversRaw || []), [driversRaw]);
  // const getUsers = useCallback((token) => {
  //   UserService.getLoggedInUser(token)
  //     .then(res => {
  //       if (res.data.data) {
  //         const userObj = res.data.data;
  //         setLoggedInUser(userObj)
  //       }
  //     })
  //     .catch(() => {
  //     })

  // }, [user?.signInUserSession?.accessToken?.jwtToken, order, orderBy, page, perPage])

  // useEffect(() => {
  //   getUsers(user?.signInUserSession?.accessToken?.jwtToken)
  // }, [user?.signInUserSession?.accessToken?.jwtToken])

  // const addEditDriver = useCallback(() => {
  //   setAddEditDriverLoading(true);

  //   let payload = {
  //     driverName: driverDetails.driverName.trim(),
  //     licenceNumber: driverDetails.licenceNumber.trim(),
  //     carrierIds: driverDetails.carrierIds.map(item => item.value),
  //     driverIds: Object.values(driverDetails.terminals).map(item => {
  //       return {
  //         tcnId: item.terminal_control_number,
  //         driver_id: item.driver_id.trim(),
  //         active: item.active,
  //         renewal_date: item.renewal_date
  //       }
  //     })
  //   }

  //   DriverService.addDriver(user?.signInUserSession?.accessToken?.jwtToken, payload)
  //   .then(() => {
  //     setAddEditDriverLoading(false)
  //     setDriverAdded(true)
  //     closeModal()
  //   })
  // }, [
  //   user?.signInUserSession?.accessToken?.jwtToken,
  //   JSON.stringify(driverDetails)
  // ])

  // const handleCarriersInputChange = (val) => {
  //   setCarriersInputValue(val);
  // }

  // const handlCarrierSelectionChange = useCallback((carriers) => {
  //   const tempDriverDetails = Object.assign({}, driverDetails);
  //   tempDriverDetails.carrierIds = carriers
  //   setDriverDetails(tempDriverDetails);
  //   handleCarriersInputChange(carriersInputValue)
  // }, [carriersInputValue, JSON.stringify(driverDetails)])

  // const loadDomainOptions = (inputValue) => {
  //   return new Promise((resolve, rej) => {
  //     DomainService.getDomains({
  //       token: user?.signInUserSession?.accessToken?.jwtToken,
  //       searchText: inputValue,
  //       order: 'ASC',
  //       orderBy: 'domain_name',
  //       page: 1,
  //       perPage: 1000,
  //     })
  //       .then(res => {
  //         const tempCarriersOptions = (res.data.data || []).map(item => {
  //           const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ''}`
  //           return { value: item._id, label }
  //         })
  //         resolve(tempCarriersOptions)
  //       })
  //       .catch(err => {
  //         resolve([])
  //       })
  //   })
  // }

  const { data: carrierOptions } = useFetch({
    service: DomainService,
    func: 'getDomains',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order: 'ASC',
      orderBy: 'domain_name',
      page: 1,
      perPage: 1000,
      searchText: ''
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken]
  });

  // const { data: terminalOptions } = useFetch({
  //   service: TerminalService,
  //   func: 'getTerminals',
  //   payload: {
  //     token: user?.signInUserSession?.accessToken?.jwtToken,
  //     searchText: '',
  //     pageSize: 'all'
  //   },
  //   dependency: [user?.signInUserSession?.accessToken?.jwtToken]
  // });

  // const handleDriverNameChange = (e) => {
  //   setDriverDetails(item => {
  //     return {
  //       ...item,
  //       driverName: e.target.value
  //     }
  //   })
  // }

  // const handleLicenceNumberChange = (e) => {
  //   setDriverDetails(item => {
  //     return {
  //       ...item,
  //       licenceNumber: e.target.value
  //     }
  //   })
  // }

  // const handleAddMoreRow = useCallback((e) => {
  //   const newUniqueId = uuid();
  //   let tempDriverDetails = Object.assign({}, driverDetails);
  //   tempDriverDetails.terminals = {
  //     ...tempDriverDetails.terminals,
  //     ...{
  //       [newUniqueId]: {
  //         terminal_control_number: '',
  //         driver_id: '',
  //         active: false,
  //         renewal_date: ''
  //       }
  //     }
  //   }
  //   setDriverDetails(tempDriverDetails)
  // }, [JSON.stringify(driverDetails)])

  // const handleTerminalDetailsChange = useCallback((e, uniqueId, name) => {
  //   const tempDriverDetails = Object.assign({}, driverDetails);
  //   if (name === 'active') {
  //     tempDriverDetails.terminals[uniqueId][name] = e.target.checked
  //   } else {
  //     tempDriverDetails.terminals[uniqueId][name] = e.target.value
  //   }
  //   setDriverDetails(tempDriverDetails)
  // }, [JSON.stringify(driverDetails)])

  // const addEditModalTitle = useMemo(() => {
  //   return "Add Driver"
  // }, []);

  // const addEditModalContent = useMemo(() => {
  //   return (
  //     <div className="add-driver">
  //       <div className="driver-details">
  //         <div className="driver-primary-details">
  //           <div className="form-field-box">
  //             <label>Driver Name <span className="required">*</span></label>
  //             <input value={driverDetails.driverName} onChange={handleDriverNameChange} placeholder="Enter Driver Name" />
  //             <label className="d-error">{!driverDetails.driverName.trim() && 'Please Enter Driver Name'}</label>
  //           </div>
  //           <div className="form-field-box">
  //             <label>Licence Number</label>
  //             <input value={driverDetails.licenceNumber} onChange={handleLicenceNumberChange} placeholder="Enter Licence Number" />
  //           </div>
  //         </div>
  //         <div className="form-field-box">
  //           <label>Carriers <span className="required">*</span></label>
  //           <AutoComplete
  //             loadOptions={loadDomainOptions}
  //             defaultOptions={carrierOptions.map(item => {
  //               const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ''}`
  //               return { value: item._id, label }
  //             })}
  //             onChange={handlCarrierSelectionChange}
  //             value={driverDetails.carrierIds}
  //             onInputChange={handleCarriersInputChange}
  //             inputValue={carriersInputValue}
  //           />
  //           {!driverDetails.carrierIds || !driverDetails.carrierIds.length && <label className="d-error">Atleast one carrier selection is required</label>}
  //         </div>
  //       </div>
  //       <div className="terminal-details">
  //         <div className="terminal-details-title">
  //           <label>Terminals</label>
  //           <Tooltip
  //             title="Add more rows below"
  //             arrow
  //             classes={{
  //               tooltip: 'tooltip',
  //             }}
  //           >
  //             <AddBoxRoundedIcon className="add-more-terminals" onClick={handleAddMoreRow} />
  //           </Tooltip>
  //         </div>
  //         <div className="terminal-details-rows">
  //           {
  //             Object.keys(driverDetails.terminals).map(terminalItemKey => {
  //               return (
  //                 <div className="terminal-row-main">
  //                   <div className="form-field-box tcn">
  //                     <label>Terminal Control Number <span className="required">*</span></label>
  //                     <Dropdown
  //                       options={
  //                         terminalOptions.filter(terminalOptionItem => {
  //                           const selectedTCNIds = Object.values(driverDetails.terminals).map(itm => itm.terminal_control_number);
  //                           if(selectedTCNIds.find(item => item === terminalOptionItem._id)) {
  //                             return false
  //                           } else {
  //                             return true
  //                           }
  //                         }).map(item => {
  //                           return {
  //                             value: item._id,
  //                             label: item.control_number
  //                           }
  //                         })
  //                       }
  //                       onChange={(newValue) => handleTerminalDetailsChange({ target: {value: newValue.value} }, terminalItemKey, 'terminal_control_number')}
  //                       searchable
  //                     />
  //                   </div>
  //                   <div className="form-field-box driver-id">
  //                     <label>Driver Id <span className="required">*</span></label>
  //                     <input placeholder="Enter Driver Id" value={driverDetails.terminals[terminalItemKey]['driver_id']} onChange={(e) => handleTerminalDetailsChange(e, terminalItemKey, 'driver_id')} />
  //                   </div>
  //                   <div className="form-field-box active">
  //                     <label>Active <span className="required">*</span></label>
  //                     <input type={'checkbox'} className="active-selection" checked={!!driverDetails.terminals[terminalItemKey]['active']} onChange={(e) => handleTerminalDetailsChange(e, terminalItemKey, 'active')} />
  //                   </div>
  //                   <div className="form-field-box renewal-date">
  //                     <label>Renewal Date <span className="required">*</span></label>
  //                     <input type={'date'} value={driverDetails.terminals[terminalItemKey]['renewal_date']} onChange={(e) => handleTerminalDetailsChange(e, terminalItemKey, 'renewal_date')} />
  //                   </div>
  //                 </div>
  //               )
  //             })
  //           }
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }, [
  //   JSON.stringify(driverDetails),
  //   JSON.stringify(terminalOptions),
  //   JSON.stringify(carrierOptions), carriersInputValue,
  // ])

  // const btnDisabled = useMemo(() => {
  //   if(!driverDetails.driverName) {
  //     return true
  //   }

  //   if(driverDetails.carrierIds.length <= 0) {
  //     return true
  //   }

  //   const emptyTerminalRowsCount = Object.values(driverDetails.terminals).filter(terminalItem => {
  //     return (!terminalItem.terminal_control_number || !terminalItem.driver_id || !terminalItem.renewal_date)
  //   })

  //   if(emptyTerminalRowsCount && emptyTerminalRowsCount.length) {
  //     return true
  //   }

  //   return false
  // }, [JSON.stringify(driverDetails)])

  // const addEditModalAction = useMemo(() => {
  //   let btnContent;

  //   if (addEditDriverLoading) {
  //     btnContent = <Spinner size={22} color="white" />
  //   } else {
  //     btnContent = "Add"
  //   }

  //   return (
  //     <div className="add-domain-btn-div">
  //       <button className={`btn add-domain-btn ${btnDisabled && 'disabled'}`} disabled={btnDisabled} onClick={addEditDriver}>
  //         {btnContent}
  //       </button>
  //     </div>
  //   )
  // }, [btnDisabled, addEditDriverLoading, addEditDriver])


  const headCells = [{
    id: 'carrier_id.carrier_alias',
    accessor: 'carrier_id.carrier_alias',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Carrier Alias",
    width: "30%",
  }, {
    id: 'driver_name',
    accessor: 'driver_name',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Name",
    width: "30%",
  }, {
    id: 'terminals',
    accessor: 'terminals',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Total Terminals",
    width: "30%",
  }]

  const handleCarrierFilterChange = (event) => {
    setCarrierIdFilter(event.target.value)
    setPage(1)
  }

  const dropdownValue = useMemo(() => {
    if (carrierIdFilter === 'all') {
      return {
        value: 'all',
        label: 'All'
      }
    } else {
      const tempOption = carrierOptions.find(item => item._id === carrierIdFilter)
      const label = `${tempOption.domain_name} ${tempOption.carrier_alias ? `(${tempOption.carrier_alias})` : ''}`
      return {
        value: tempOption._id,
        label: label
      }
    }
  }, [carrierIdFilter])

  const exportDrivers = useCallback(() => {
    setExportLoading(true)
    DriverService.exportDrivers({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      carrierId: dropdownValue.value
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const exportLinkElement = document.createElement('a');

        exportLinkElement.hidden = true;
        exportLinkElement.download = `${dropdownValue.label}.csv`;
        exportLinkElement.href = url;
        exportLinkElement.text = "downloading...";

        document.body.appendChild(exportLinkElement);
        exportLinkElement.click();
        setExportLoading(false)
        exportLinkElement.remove();
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong", { icon: false });
        setExportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, dropdownValue])

  const importDrivers = useCallback((file) => {
    setImportLoading(true);
    // const formBody = new FormData();
    // formBody.append('file', file);
    // formBody.append('domainId', dropdownValue.value);
    DriverService.importDrivers({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      payload: {
        domainId: dropdownValue.value,
        fileKey: fileKey
      }
    })
      .then(response => {
        toast.success("File imported successfully.", { icon: false });
        setImportLoading(false);
        setReload(prev => !prev)
        setPage(1)
        closeModal()
      })
      .catch((err) => {
        toast.error(err.response.data.message || "Something went wrong", { icon: false });
        setImportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, dropdownValue, fileKey])

  // const handleFileDrop = (acceptedFiles, fileRejections) => {
  //   if (fileRejections && fileRejections.length > 0) {
  //     fileRejections.forEach((file) => {
  //       file.errors.forEach((err) => {
  //         if (err.code === 'file-too-large') {
  //           toast.error("Imported file is too large", { icon: false });
  //         }
  //       });
  //     });
  //   }

  //   if (acceptedFiles.length > 0) {
  //     importDrivers(acceptedFiles[0])
  //   }
  // };

  // const {
  //   open,
  // } = useDropzone({
  //   noClick: false,
  //   noKeyboard: true,
  //   maxFiles: 1,
  //   accept: {
  //     'text/csv': ['.csv'],
  //   },
  //   maxSize: MAX_FILE_UPLOAD_SIZE,
  //   onDrop: handleFileDrop,
  // });

  const handleFileKeyChange = (e) => {
    setFileKey(e.target.value);
  }

  const modalContent = useMemo(() => {
    return (
      <div className="add-domain">
        <div className="form-field-box">
          <label>S3 File Key</label>
          <input value={fileKey} onChange={handleFileKeyChange} placeholder="S3 File Key" />
          <label className="d-error">{!fileKey.trim() && 'Please Enter S3 File Key'}</label>
        </div>
      </div>
    )
  }, [fileKey])

  const modalAction = useMemo(() => {
    let btnContent;

    if (importLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      btnContent = "Import"
    }

    return (
      <div className="add-domain-btn-div">
        <button className={`btn add-domain-btn ${!fileKey && 'disabled'}`} disabled={!fileKey} onClick={importDrivers}>
          {btnContent}
        </button>
      </div>
    )
  }, [fileKey, importLoading])

  useEffect(() => {
    setPage(1)
  }, [actAsDomainIdMemoised])

  return (
    <div className="root">
      <div className="table-layout">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Drivers</h3>
          {
            ((user.attributes['custom:role'] === "superadmin" || loggedInUser?.domain?.isProxyDomain) && !actAsDomainIdMemoised) && <div className="filter-box">
              <Dropdown
                options={
                  [
                    {
                      value: 'all',
                      label: 'All'
                    },
                    ...carrierOptions.map(item => {
                      const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ''}`
                      return {
                        value: item._id,
                        label
                      }
                    })
                  ]
                }
                value={dropdownValue}
                onChange={(newValue) => handleCarrierFilterChange({ target: { value: newValue.value } })}
                searchable
                className='carrier-filter'
              />
              {
                user.attributes['custom:role'] === "superadmin" && <>
                  <button className={`btn`} onClick={openModal}>Import
                    {/* {
                  importLoading ? <Spinner size={22} color="white" /> : 'Import'
                } */}
                  </button>
                  <button className={`btn`} onClick={exportDrivers}>
                    {
                      exportLoading ? <Spinner size={22} color="white" /> : 'Export'
                    }
                  </button>
                </>
              }
            </div>
          }
          {/* <button className="btn add-domain-btn" onClick={openModal}>Add Driver</button> */}
        </div>
        <div className="domains-table">
          <Table
            data={drivers}
            loading={driversLoading}
            total={total}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={DriverRow}
            // rowProps={{ handleEdit, handleDelete }}
            noDataFoundMessage={`No Drivers Found`}
          />
        </div>
      </div>
      <ImportModal
        open={open}
        title={modalTitle}
        content={modalContent}
        action={modalAction}
        onClose={closeModal}
      />
      {/* <AddEditDriver
        open={open}
        title={addEditModalTitle}
        content={addEditModalContent}
        action={addEditModalAction}
        paperClassName={"add-driver-paper"}
        maxWidth={'lg'}
        onClose={closeModal}
      /> */}
    </div>
  )
};

export default ManageDrivers;