import React, { useState } from 'react';
import { TableRow, IconButton, TableCell } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import { dateFormat } from "../../utils/methods";
import _ from "lodash";

const causingFieldsExcludeArray = ['driver_name'];
const causingFieldsArrayValues = ['product_code'];

const Row = ({ item, headCells, user }) => {
  const ExpandableTableRow = ({ children, itemData }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    let translatedData = _.get(itemData, 'translated_data')

    if(!itemData?.metered_load?.terminal_control_number) {
      translatedData = {
        ...translatedData,
        terminal_control_number: {
          error: itemData?.processing_failing_reason
        }
      }
    }

    return (
      <>
        <TableRow className="table-body-row">
          <TableCell>
            <IconButton classes={{
              root: 'icon-btn-root'
            }} onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow className="table-body-row-expanded">
            <TableCell colSpan={headCells.length}>
              <label className='table-body-row-expanded-label'>Fields causing cross ref errors, in detail</label>
              {

                translatedData && <ul className='table-body-row-expanded-list'>
                  {
                    Object.keys(translatedData).map(translatedDataItem => {
                      if (!causingFieldsExcludeArray.includes(translatedDataItem)) {
                        if (causingFieldsArrayValues.includes(translatedDataItem)) {
                          return translatedData[translatedDataItem].map(translatedDataItemInnerItem => {
                            if (translatedDataItemInnerItem.error) {
                              return <li className='table-body-row-expanded-list-item'>
                                <label className='table-body-row-expanded-list-item-label'>{translatedDataItem} - {translatedDataItemInnerItem.from} </label>
                                <span>({translatedDataItemInnerItem.error})</span>
                              </li>
                            }
                          })
                        } else {
                          if (translatedData[translatedDataItem].error) {
                            return <li className='table-body-row-expanded-list-item'>
                              <label className='table-body-row-expanded-list-item-label'>{translatedDataItem} </label>
                              <span>({translatedData[translatedDataItem].error})</span>
                            </li>
                          }
                        }
                      }
                    })
                  }
                </ul>
              }
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <ExpandableTableRow
      key={item._id}
      itemData={item}
    >
      {
        headCells.filter(item => item.id !== 'expand-collapse').map((headCell) => {
          if (headCell.id === 'field_values') {
            const fieldValuesArray = [];

            const translatedData = _.get(item, headCell.accessor)
            if (translatedData) {
              Object.keys(translatedData).forEach(translatedDataItem => {
                if (!causingFieldsExcludeArray.includes(translatedDataItem)) {
                  if (causingFieldsArrayValues.includes(translatedDataItem)) {
                    let occurenceCount = 0;
                    translatedData[translatedDataItem].forEach(translatedDataItemInnerItem => {
                      if (translatedDataItemInnerItem.error) {
                        occurenceCount += 1
                      }
                    })

                    if (occurenceCount) {
                      fieldValuesArray.push(translatedDataItem)
                    }
                  } else {
                    if (translatedData[translatedDataItem].error) {
                      fieldValuesArray.push(translatedDataItem)
                    }
                  }
                }
              })
            }

            if(!item?.metered_load?.terminal_control_number) {
              fieldValuesArray.push('terminal_control_number')
            }

            return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={fieldValuesArray.join(',')} />);
          }

          if(headCell.id === 'created_date') {
            const value = _.get(item, headCell.accessor);
            return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={value ? dateFormat(new Date(value).toISOString()) : ''} />);  
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={_.get(item, headCell.accessor)} />);
        })
      }
    </ExpandableTableRow>
  );
};

export default Row;
