import http from '../httpConfig';

const getCarrierIdsToTcn = ({ token, order, orderBy, page, perPage, carrierIdFilter }) => {
  let url = `/carrier-ids-to-tcn?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}`
  if(carrierIdFilter) {
    url += `&carrierId=${carrierIdFilter}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const exportData = ({ token, carrierId }) => {
  return http.get(`/carrier-ids-to-tcn/export?carrierId=${carrierId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const importData = ({ token, payload }) => {
  return http.post(`/carrier-ids-to-tcn/import`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const CarrierIdsToTcnService = {
  getCarrierIdsToTcn,
  exportData,
  importData
};

export default CarrierIdsToTcnService;