import React, { useCallback, useMemo, useState, useEffect } from "react";
import AddEditProxy from "../components/popup";
import Spinner from "../components/circularProgress";
import ProxyService from "../services/proxy.service";
import DomainService from "../services/domain.service";
import Table from "../components/Table";
import Dropdown from "../components/dropdown";
import ProxyRow from "../components/manageProxies/row";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder } from "../utils/appConstants";
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from "../utils/methods";

const ManageProxies = ({ user, actAsDomainIdMemoised, loggedInUser }) => {
  const [addEditProxyLoading, setAddEditProxyLoading] = useState(false);
  const [deleteProxyLoading, setDeleteProxyLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editProxyId, setEditProxyId] = useState("");
  const [domain_id, setDomainId] = useState("");
  const [deleteProxyFlag, setDeleteProxyFlag] = useState(false);
  const [deleteProxyId, setDeleteProxyId] = useState("");
  const [open, setOpen] = useState(false);
  const [proxyAdded, setProxyAdded] = useState(false);
  const [proxyName, setProxyName] = useState("");

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
    setProxyName("");
    setDomainId('');
  }

  const {
    order, orderBy, setOrder, setOrderBy,
  } = useSorting({ defaultOrder: SortOrder.asc, defaultOrderBy: 'created_date' });
  const {
    page, perPage, setPage, setPerPage,
  } = usePagination({ defaultPage: 1, defaultPerPage: 10 });

  const { data: proxiesRaw, total: total, loading: proxiesLoading } = useFetch({
    service: ProxyService,
    func: 'getProxies',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order, orderBy, page, perPage,
      searchText: '',
      showDeleted: true,
      needPopulation: true,
      id: actAsDomainIdMemoised || (user.attributes['custom:role'] !== "superadmin" ? loggedInUser.domain_id : "")
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, proxyAdded, order, orderBy, page, perPage, loggedInUser.domain_id, user.attributes['custom:role'], actAsDomainIdMemoised],
    onSuccess: () => setProxyAdded(false),
    skip: user.attributes['custom:role'] !== "superadmin" && !loggedInUser.domain_id
  });

  const transformProxyData = (data) => {
    return data.map(proxy => ({
      ...proxy,
      name: capitalizeFirstLetter(proxy?.name),
    }));
  };
  const proxies = useMemo(() => transformProxyData(proxiesRaw || []), [proxiesRaw]);

  const { data: domains } = useFetch({
    service: DomainService,
    func: 'getDomains',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order: 'ASC',
      orderBy: 'domain_name',
      page: 1,
      perPage: 1000,
      searchText: '',
      isList: true,
      isProxyDoamins: true
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken],
  });

  const addEditProxy = useCallback(() => {
    setAddEditProxyLoading(true);

    let payload = {
      name: proxyName.trim(),
      domain_id: domain_id
    }

    if (edit) {
      payload.proxyId = editProxyId;
      ProxyService.editProxy(user?.signInUserSession?.accessToken?.jwtToken, payload)
        .then(() => {
          setAddEditProxyLoading(false)
          setProxyAdded(true)
          closeModal()
        })
    } else {
      ProxyService.addProxy(user?.signInUserSession?.accessToken?.jwtToken, payload)
        .then(() => {
          setAddEditProxyLoading(false)
          setProxyAdded(true)
          closeModal()
        })
    }
  }, [
    proxyName, edit, editProxyId, domain_id,
    user?.signInUserSession?.accessToken?.jwtToken
  ])

  const deleteProxy = useCallback(() => {
    setDeleteProxyLoading(true);

    ProxyService.deleteProxy(user?.signInUserSession?.accessToken?.jwtToken, deleteProxyId)
      .then(() => {
        setDeleteProxyLoading(false)
        setProxyAdded(true)
        closeModal()
      })
      .catch(error => {
        setDeleteProxyLoading(false)
        if(error.response.data.code < 500) {
          toast.error(error.response.data.message || "Something went wrong", { icon: false });
        } else {
          toast.error("Something went wrong", { icon: false });
        }
      })
  }, [
    user?.signInUserSession?.accessToken?.jwtToken,
    deleteProxyId
  ])

  const handleInputChange = (e) => {
    setProxyName(e.target.value);
  }

  const handleDomainChange = (e) => {
    setDomainId(e.target.value);
  }

  const handleEdit = useCallback((proxyData) => {
    setEdit(true)
    setOpen(true)
    setProxyName(proxyData.name)
    setEditProxyId(proxyData._id)
    setDomainId(proxyData.domain_id)
  }, [])

  const handleDelete = (proxyData) => {
    setOpen(true)
    setDeleteProxyFlag(true)
    setDeleteProxyId(proxyData._id)
  }

  const addEditProxyTitle = useMemo(() => {
    return edit ? "Edit Proxy" : "Add Proxy"
  }, [edit]);

  const addEditModalContent = useMemo(() => {
    let dropdownValue = "";
    const selectedDomain = domains.find(itemDomain => itemDomain._id === domain_id)
    if (selectedDomain) {
      const label = `${selectedDomain.domain_name} ${selectedDomain.carrier_alias ? `(${selectedDomain.carrier_alias})` : ''}`

      dropdownValue = {
        value: selectedDomain ? selectedDomain._id : "",
        label: selectedDomain ? label : ""
      }
    }

    return (
      <div className="add-proxy">
        <div className="form-field-box">
          <label>Proxy Name <span className="required">*</span></label>
          <input value={proxyName} onChange={handleInputChange} placeholder="Enter Proxy Name" />
          <label className="d-error">{!proxyName.trim() && 'Please Enter Proxy Name'}</label>
        </div>
        <div className="form-field-box">
          <label>Domain</label>
          <Dropdown
            options={
              domains.map(item => {
                const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ''}`
                return {
                  value: item._id,
                  label
                }
              })
            }
            onChange={(newValue) => handleDomainChange({ target: { value: newValue.value } })}
            value={dropdownValue}
            searchable
          />
          <label className="d-error">{!domain_id && 'Please Select Domain'}</label>
        </div>
      </div>
    )
  }, [
    proxyName, edit, JSON.stringify(domains), domain_id
  ])

  const btnDisabled = useMemo(() => {
    if (!proxyName.trim() || !domain_id) {
      return true
    } else if (addEditProxyLoading) {
      return true
    } else {
      return false
    }
  }, [proxyName, addEditProxyLoading, domain_id])

  const addEditModalAction = useMemo(() => {
    let btnContent;

    if (addEditProxyLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      if (edit) {
        btnContent = "Update"
      } else {
        btnContent = "Add"
      }
    }

    return (
      <div className="add-domain-btn-div">
        <button className={`btn add-domain-btn ${btnDisabled && 'disabled'}`} disabled={btnDisabled} onClick={addEditProxy}>
          {btnContent}
        </button>
      </div>
    )
  }, [btnDisabled, addEditProxyLoading, edit, addEditProxy])

  const deleteProxyTitle = "Delete Proxy";

  const deleteModalContent = "Are you sure you want to delete this proxy?"

  const deleteModalAction = useMemo(() => {
    let btnContent;

    if (deleteProxyLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      btnContent = "Delete"
    }

    return (
      <div className="add-domain-btn-div">
        <button className="btn danger" onClick={deleteProxy}>
          {btnContent}
        </button>
      </div>
    )
  }, [deleteProxyLoading, deleteProxy])

  useEffect(() => {
    setPage(1)
  }, [actAsDomainIdMemoised])

  const headCells = [{
    id: 'expand-collapse',
    accessor: 'expand-collapse',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "",
    width: "5%",
  }, {
    id: 'name',
    accessor: 'name',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Name",
    width: "35%",
  }, {
    id: 'created_date',
    accessor: 'created_date',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Created Date",
    width: "25%",
  }, {
    id: 'domains_count',
    accessor: 'domains_count',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Domains Count",
    width: "15%",
  }, {
    id: 'action',
    accessor: 'action',
    align: 'center',
    disablePadding: false,
    sortEnabled: false,
    label: "Action",
    width: "20%",
  }]

  return (
    <div className="root">
      <div className="table-layout">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Proxies</h3>
          {(user.attributes['custom:role'] === "superadmin" && !actAsDomainIdMemoised) && <button className="btn add-domain-btn" onClick={openModal}>Add Proxy</button>}
        </div>
        <div className="domains-table">
          <Table
            data={proxies}
            loading={proxiesLoading || !loggedInUser?._id}
            total={total}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={ProxyRow}
            rowProps={{ handleEdit, handleDelete }}
            noDataFoundMessage={`No Proxies Found`}
          />
        </div>
      </div>
      <AddEditProxy
        open={open}
        maxWidth="md"
        title={deleteProxyFlag ? deleteProxyTitle : addEditProxyTitle}
        content={deleteProxyFlag ? deleteModalContent : addEditModalContent}
        action={deleteProxyFlag ? deleteModalAction : addEditModalAction}
        paperClassName={deleteProxyFlag ? "" : "add-proxy-paper"}
        onClose={closeModal}
      />
    </div>
  )
};

export default ManageProxies;