import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import _ from "lodash";

const Row = ({ item, headCells }) => {
  return (
    <TableRow className="table-body-row">
      {
        headCells.map((headCell) => {
          if (headCell.id === 'add_a_line_item') {
            const extraLineItemCode = _.get(item, 'extra_line_item_code');
            return (
              <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                <label>{extraLineItemCode ? 'TRUE' : 'FALSE'}</label>
              </TableCell>
            )
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={_.get(item, headCell.accessor)} />);
        })
      }
    </TableRow>
  );
};

export default Row;
