import React from "react";
import ExpandCollapseIcon from "../../images/expand-collapse.svg";
import UserSevice from "../../services/user.service";
import { dateFormat } from "../../utils/methods";
import useFetch from "../../hooks/useFetch";

const Header = ({ handleCollapse, user }) => {
  const { data: lastActivityTimeData } = useFetch({
    service: UserSevice,
    func: 'getLastActivityTime',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, window.location.pathname]
  });

  // 

  return (
    <div className="header">
      <div className="menu-collaps" onClick={handleCollapse}>
        <img src={ExpandCollapseIcon} alt="Expand Collapse" />
      </div>
      <div className="user-section">
        <label>{user.attributes.email}</label>
        <span>Last Activity: {lastActivityTimeData.last_activity_time ? dateFormat(lastActivityTimeData.last_activity_time) : 'Not Active Yet'}</span>
      </div>
    </div>
  )
};

export default Header;