import React from 'react';
import Pagination from '@mui/material/Pagination';
import PagingText from './pagingText';
import PagingItem from './paginationItem';
import Dropdown from "../dropdown";
import CaretRight from '../../images/caret-right.svg';
import CaretLeft from '../../images/caret-left.svg';

const perPagesOptions = [
  {
    label: 10,
    value: 10
  },
  {
    label: 20,
    value: 20
  },
  {
    label: 50,
    value: 50
  },
  {
    label: 100,
    value: 100
  }
]

const PaginationWidget = ({
  total, page, perPage, setPage, setPerPage
}) => {
  const totalPages = Math.ceil(total / perPage);

  return (
    <div className="table-pagination">
      <Pagination 
        count={totalPages} 
        page={page}
        color="primary" 
        shape='rounded' 
        variant="outlined" 
        onChange={(e, page) => setPage(page)} 
        classes={{
          ul: 'pagination-items-root'
        }}
      />
      <PagingText 
        total={total}
        page={page}
        perPage={perPage}
      />
      <div className='per-page-widget'>
        <label>Per Page: </label>
        <Dropdown
          options={perPagesOptions}
          value={{
            label: perPage,
            value: perPage
          }}
          onChange={(newValue) => {
            setPage(1)
            setPerPage(newValue.value)
          }}
        />
      </div>
    </div>
  );
};

export default PaginationWidget;
