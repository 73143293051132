import {
  useState, useMemo, useEffect, useCallback,
} from 'react';

const useFetch = ({
  service, func, payload, skip, dependency, onError, onSuccess,
}) => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = useCallback(() => {
    setLoading(true);
    service[func](payload)
      .then((response) => {
        setLoading(false);
        setData(response.data.data || []);
        setTotal(response.data.totalRecords || 0);
        onSuccess && onSuccess(response);
      })
      .catch((error) => {
        setLoading(false);
        setData([]);
        setTotal(0);
        onError && onError(error);
      });
  }, [service, func, payload]);

  useEffect(() => {
    if (!skip) {
      getData();
    }
  }, dependency);

  return useMemo(() => {
    return {
      total, loading, data,
    };
  }, [total, loading, data]);
};

export default useFetch;
