import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <label>Copyright {new Date().getFullYear()} Tandem Concepts, Inc.</label>
      <div className="links">
        <a href="https://www.tandemconcepts.com/" target={"_blank"} rel="noreferrer" className="link">About Us</a>
      </div>
    </div>
  )
};

export default Footer;