import http from '../httpConfig';

const getRoles = (token) => {
  return http.get('/roles', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const RoleService = {
  getRoles
};

export default RoleService;