import React from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';

const Spinner = ({ size, color, className }) => {
  return (
    <CircularProgress className={clsx('spinner', color, className)} size={size} data-testid="spinner" />
  );
};

export default Spinner;
