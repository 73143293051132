import http from '../httpConfig';

const getProducts = ({ token, order, orderBy, page, perPage, carrierIdFilter }) => {
  let url = `/products?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}`
  if(carrierIdFilter) {
    url += `&carrierId=${carrierIdFilter}`
  }

  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const exportProducts = ({ token, carrierId }) => {
  return http.get(`/products/export?carrierId=${carrierId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const importProducts = ({ token, payload }) => {
  return http.post(`/products/import`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const ProductService = {
  getProducts,
  exportProducts,
  importProducts
};

export default ProductService;