import React from "react";
import Header from "./header";
import Footer from "./footer";

const RightSection = ({ children, handleCollapse, collapse, user }) => {
  return (
    <div className={collapse ? "layout-right layout-right-section-collapsed" : "layout-right layout-right-section"}>
      <Header handleCollapse={handleCollapse} user={user} />
      <div className="content-main">
        {children}
      </div>
      <Footer />
    </div>
  )
};

export default RightSection;