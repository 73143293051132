import React from 'react';
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from "@mui/material";

const MultiDropdown = ({
    selectedColumns, handleChange, renderSelectedLabels, disabledColumns, headCells
}) => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
            },
        },
    };
    return (
        <FormControl sx={{ m: 2, width: 250 }}>
            <Select
                id="filter-multiple-checkbox"
                multiple
                value={selectedColumns}
                onChange={handleChange}
                renderValue={renderSelectedLabels}
                MenuProps={MenuProps}
            >
                {headCells.map((col) => (
                    <MenuItem key={col.id} value={col.id} disabled={disabledColumns.includes(col.id)}>
                        <Checkbox checked={selectedColumns.indexOf(col.id) > -1} />
                        <ListItemText primary={col.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiDropdown;
