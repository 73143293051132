import React, { useState, useCallback, useMemo, useEffect } from "react";
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import ImportModal from "../components/popup";
import Dropdown from "../components/dropdown";
import Table from "../components/Table";
import Spinner from "../components/circularProgress";
import ShipperIdRow from "../components/manageShipperIds/row";
import ShipperIdService from "../services/shipperId.service";
import DomainService from "../services/domain.service";
import UserService from "../services/user.service";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder, MAX_FILE_UPLOAD_SIZE } from "../utils/appConstants";
import { capitalizeFirstLetter } from "../utils/methods";

const ManageShipperIds = ({ user, actAsDomainIdMemoised, loggedInUser }) => {
  const [selectedDomain, setSelectedDomain] = useState({
    label: 'All',
    value: 'all'
  })
  // const [loggedInUser, setLoggedInUser] = useState({})
  const modalTitle = 'Import'
  const [open, setOpen] = useState(false);
  const [fileKey, setFileKey] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [reload, setReload] = useState(true);

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
    setFileKey('');
  }

  const handleSelectedDomainChange = (e) => {
    setSelectedDomain(e.target.value)
    setPage(1)
  }

  const {
    order, orderBy, setOrder, setOrderBy,
  } = useSorting({ defaultOrder: SortOrder.asc, defaultOrderBy: 'carrier_shipper_id' });
  const {
    page, perPage, setPage, setPerPage,
  } = usePagination({ defaultPage: 1, defaultPerPage: 10 });

  const { data: shipperIdsMappingsRaw, loading, total } = useFetch({
    service: ShipperIdService,
    func: 'getShipperIds',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order, orderBy, page, perPage,
      carrierIdFilter: actAsDomainIdMemoised || ((user.attributes['custom:role'] === "superadmin" || loggedInUser?.domain?.isProxyDomain) ? selectedDomain.value : loggedInUser.domain_id)
    },
    dependency: [reload, user?.signInUserSession?.accessToken?.jwtToken, order, orderBy, page, perPage, selectedDomain.value, loggedInUser.domain_id, user.attributes['custom:role'], actAsDomainIdMemoised],
    skip: user.attributes['custom:role'] !== "superadmin" && !loggedInUser.domain_id
  });

  const transformShipperData = (data) => {
    return data.map(shipper => ({
      ...shipper,
      carrier_description: capitalizeFirstLetter(shipper?.carrier_description),
      terminal_description: capitalizeFirstLetter(shipper?.terminal_description),
    }));
  };
  const shipperIdsMappings = useMemo(() => transformShipperData(shipperIdsMappingsRaw || []), [shipperIdsMappingsRaw]);

  const { data: domains } = useFetch({
    service: DomainService,
    func: 'getDomains',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order: 'ASC',
      orderBy: 'domain_name',
      page: 1,
      perPage: 1000,
      searchText: ''
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken]
  });

  // const getUsers = useCallback((token) => {
  //   UserService.getLoggedInUser(token)
  //     .then(res => {
  //       if(res.data.data) { 
  //         const userObj = res.data.data;
  //         setLoggedInUser(userObj)
  //       }
  //     })
  //     .catch(() => {
  //     })

  // }, [user?.signInUserSession?.accessToken?.jwtToken, order, orderBy, page, perPage])

  const headCells = [{
    id: 'carrier_id.carrier_alias',
    accessor: 'carrier_id.carrier_alias',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Carrier Alias",
    width: "10%",
  }, {
    id: 'tcn_id.control_number',
    accessor: 'tcn_id.control_number',
    align: 'left',
    disablePadding: false,
    sortEnabled: false,
    label: "Terminal Control Number",
    width: "15%",
  }, {
    id: 'carrier_shipper_id',
    accessor: 'carrier_shipper_id',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Carrier Shipper Id",
    width: "15%",
  }, {
    id: 'terminal_shipper_id',
    accessor: 'terminal_shipper_id',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Terminal Shipper Id",
    width: "20%",
  }, {
    id: 'carrier_description',
    accessor: 'carrier_description',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Carrier Description",
    width: "20%",
  }, {
    id: 'terminal_description',
    accessor: 'terminal_description',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Terminal Description",
    width: "20%",
  }]

  // useEffect(() => {
  //   getUsers(user?.signInUserSession?.accessToken?.jwtToken)
  // }, [user?.signInUserSession?.accessToken?.jwtToken])

  const exportShipperIds = useCallback(() => {
    setExportLoading(true)
    ShipperIdService.exportShipperIds({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      carrierId: selectedDomain.value
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const exportLinkElement = document.createElement('a');

        exportLinkElement.hidden = true;
        exportLinkElement.download = `${selectedDomain.label}.csv`;
        exportLinkElement.href = url;
        exportLinkElement.text = "downloading...";

        document.body.appendChild(exportLinkElement);
        exportLinkElement.click();
        setExportLoading(false)
        exportLinkElement.remove();
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong", { icon: false });
        setExportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, selectedDomain])

  const importShipperIds = useCallback((file) => {
    setImportLoading(true);
    // const formBody = new FormData();
    // formBody.append('file', file);
    // formBody.append('domainId', selectedDomain.value);
    ShipperIdService.importShipperIds({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      payload: {
        domainId: selectedDomain.value,
        fileKey: fileKey
      }
    })
      .then(response => {
        toast.success("File imported successfully.", { icon: false });
        setImportLoading(false);
        setReload(prev => !prev)
        setPage(1)
        closeModal()
      })
      .catch((err) => {
        toast.error(err.response.data.message || "Something went wrong", { icon: false });
        setImportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, selectedDomain, fileKey])

  // const handleFileDrop = (acceptedFiles, fileRejections) => {
  //   if (fileRejections && fileRejections.length > 0) {
  //     fileRejections.forEach((file) => {
  //       file.errors.forEach((err) => {
  //         if (err.code === 'file-too-large') {
  //           toast.error("Imported file is too large", { icon: false });
  //         }
  //       });
  //     });
  //   }

  //   if (acceptedFiles.length > 0) {
  //     importShipperIds(acceptedFiles[0])
  //   }
  // };

  // const {
  //   open,
  // } = useDropzone({
  //   noClick: false,
  //   noKeyboard: true,
  //   maxFiles: 1,
  //   accept: {
  //     'text/csv': ['.csv'],
  //   },
  //   maxSize: MAX_FILE_UPLOAD_SIZE,
  //   onDrop: handleFileDrop,
  // });

  const handleFileKeyChange = (e) => {
    setFileKey(e.target.value);
  }

  const modalContent = useMemo(() => {
    return (
      <div className="add-domain">
        <div className="form-field-box">
          <label>S3 File Key</label>
          <input value={fileKey} onChange={handleFileKeyChange} placeholder="S3 File Key" />
          <label className="d-error">{!fileKey.trim() && 'Please Enter S3 File Key'}</label>
        </div>
      </div>
    )
  }, [fileKey])

  const modalAction = useMemo(() => {
    let btnContent;

    if (importLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      btnContent = "Import"
    }

    return (
      <div className="add-domain-btn-div">
        <button className={`btn add-domain-btn ${!fileKey && 'disabled'}`} disabled={!fileKey} onClick={importShipperIds}>
          {btnContent}
        </button>
      </div>
    )
  }, [fileKey, importLoading])

  useEffect(() => {
    setPage(1)
  }, [actAsDomainIdMemoised])

  return (
    <div className="root">
      <div className="table-layout">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Shipper Ids Mapping</h3>
          {
            ((user.attributes['custom:role'] === "superadmin" || loggedInUser?.domain?.isProxyDomain) && !actAsDomainIdMemoised) && <div className="filter-box">
              <Dropdown
                options={
                  [
                    {
                      label: 'All',
                      value: 'all'
                    },
                    ...domains.map(item => {
                      const label = `${item.domain_name} ${item.carrier_alias ? `(${item.carrier_alias})` : ''}`
                      return {
                        value: item._id,
                        label
                      }
                    })
                  ]
                }
                value={selectedDomain}
                onChange={(newValue) => handleSelectedDomainChange({ target: { value: newValue } })}
                searchable
                className={'domain-selection'}
              />
              {
                user.attributes['custom:role'] === "superadmin" && <>
                  <button className={`btn`} onClick={openModal}>Import
                    {/* {
                  importLoading ? <Spinner size={22} color="white" /> : 
                } */}
                  </button>
                  <button className={`btn`} onClick={exportShipperIds}>
                    {
                      exportLoading ? <Spinner size={22} color="white" /> : 'Export'
                    }
                  </button>
                </>
              }
            </div>
          }
        </div>
        <div className="users-table">
          <Table
            data={shipperIdsMappings}
            loading={loading}
            total={total}
            perPage={perPage}
            page={page}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            setPage={setPage}
            setPerPage={setPerPage}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={ShipperIdRow}
            noDataFoundMessage={`No Mappings Found`}
          />
        </div>
      </div>
      <ImportModal
        open={open}
        title={modalTitle}
        content={modalContent}
        action={modalAction}
        onClose={closeModal}
      />
    </div>
  )
};

export default ManageShipperIds;