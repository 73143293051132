import React from 'react';
import { TableRow } from '@mui/material';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import { dateFormat } from "../../utils/methods";
import _ from "lodash";

const Row = ({ item, headCells }) => {
  return (
    <TableRow className="table-body-row">
      {
        headCells.map((headCell) => {
          if (headCell.id === 'created_date') {
            const value = _.get(item, headCell.accessor);
            return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={value ? dateFormat(new Date(value).toISOString()) : ''} />);
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={_.get(item, headCell.accessor)} />);
        })
      }
    </TableRow>
  );
};

export default Row;
