import React from 'react';
import AuthentocatorRoutes from "./AuthentocatorRoutes";
import {
  BrowserRouter as Router, Routes, Route, Navigate
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const CustomRoutes = () => {
  return (
    <Router>
      <ToastContainer
        theme="dark"
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        draggable={false}
        closeButton={false}
        className="toast-container"
      />
      <Routes>
        <Route path={'/'} element={<Navigate replace to={{ pathname: '/home/dashboard' }} />} />
        <Route path={'/home/*'} element={<AuthentocatorRoutes />} />
        <Route path={'*'} element={<Navigate replace to={{ pathname: '/home/dashboard' }} />} />
      </Routes>
    </Router>
  )
}

export default CustomRoutes;
