import http from '../httpConfig';

const getPublicCustomerIdsMapping = ({ token, order, orderBy, page, perPage, carrierIdFilter }) => {
  let url = `/terminal-proxy-customer-ids?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}`
  if(carrierIdFilter) {
    url += `&carrierId=${carrierIdFilter}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const exportPublicCustomerIdsMapping = ({ token, carrierId }) => {
  return http.get(`/terminal-proxy-customer-ids/export?carrierId=${carrierId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const importPublicCustomerIdsMapping = ({ token, payload }) => {
  return http.post(`/terminal-proxy-customer-ids/import`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};


const PublicCustomerIdMappingService = {
  getPublicCustomerIdsMapping,
  exportPublicCustomerIdsMapping,
  importPublicCustomerIdsMapping
};

export default PublicCustomerIdMappingService;