import React from 'react';
import './App.scss';
import Routes from "./routes";
import '@aws-amplify/ui-react/styles.css';
import "react-datepicker/dist/react-datepicker.css";
import "swagger-ui-react/swagger-ui.css"
import { View, Image, Text, useTheme, Authenticator } from "@aws-amplify/ui-react";
import formFields from "./signUpFormFields.json";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import Logo from './images/logo.png';
Amplify.configure(awsconfig);


function App() {
  const { tokens } = useTheme();

  return (
    <React.Fragment>
      <Authenticator
        hideSignUp
        formFields={formFields}
        components={{
          Header() {
            return (
              <View textAlign="center" padding={tokens.space.large}>
                <Image alt="TandemLogo" src={Logo} />
              </View>
            );
          },

          Footer() {
            return (
              <View textAlign="center" padding={tokens.space.large}>
                <Text color={"#00BBE7"}>
                  &copy; All Rights Reserved
                </Text>
              </View>
            );
          },
        }}
      >
        <Routes />
      </Authenticator>
    </React.Fragment>
  );
}


export default App;
