import React from 'react';

const PagingText = ({ total, page, perPage }) => {
  const startRecord = (page - 1) * perPage + 1;
  const endRecord = Math.min(page * perPage, total);

  return (
    <div className="paging-text">
      <span>
        {`Showing ${startRecord} to ${endRecord} of ${total} records`}
      </span>
    </div>
  );
};

export default PagingText;
