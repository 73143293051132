import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import HeadCell from './headCell';

const TableHeader = ({ headCells, order, orderBy, setOrder, setOrderBy }) => {
  const cellContent = headCells.map((headCell) => {
    return (
      <HeadCell
        key={headCell.id}
        cell={headCell}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
      />
    );
  });

  return (
    <TableHead>
      <TableRow className="table-head-row">
        {cellContent}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
