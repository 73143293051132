import http from '../httpConfig';

const getTerminals = ({token, searchText, pageSize, domainCheck}) => {
  let url = `/terminals?searchText=${searchText}`;
  if(pageSize) {
    url += `&pageSize=${pageSize}`
  }
  if(domainCheck) {
    url += `&domainCheck=${domainCheck}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getActiveTerminals = ({token, searchText}) => {
  let url = `/terminals/active?searchText=${searchText}`;
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const TerminalService = {
  getTerminals,
  getActiveTerminals,
};

export default TerminalService;