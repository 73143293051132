import React, { useCallback } from 'react';
import { TableRow, TableCell, Tooltip } from '@mui/material';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from "lodash";

const Row = ({ item, headCells, handleEdit, handleDelete }) => {
  const tooltipContent = useCallback((infoId, infoObj) => {
    return (
      <div className="order-details-tooltip-content-root">
        <label className='order-details-tooltip-content-title'>{infoId === 'carrier_details' ? "Carrier" : 'Terminal'} Details</label>

        <ul className='order-details-tooltip-content-list'>
          {
            Object.keys(infoObj).map((infoItem, index) => {
              if(typeof infoObj[infoItem] === "string") {
                return (
                  <li className='order-details-tooltip-content-list-item' key={index}>
                    <p className='order-details-tooltip-content-list-item-root'>
                      <label className='order-details-tooltip-content-list-item-key'>{infoItem}: </label>
                      <span className='order-details-tooltip-content-list-item-value'>{infoObj[infoItem]}</span>
                    </p>
                  </li>
                )
              } else {
                return null
              }
            }).filter(item => item)
          }
        </ul>
      </div>
    )
  })

  return (
    <TableRow className="table-body-row">
      {
        headCells.map((headCell) => {
          if (headCell.id === 'carrier_details' || headCell.id === 'terminal_details') {
            let infoObj;

            if(headCell.id === 'carrier_details' && item.carrier) {
              infoObj = item.carrier
            } else if (headCell.id === 'terminal_details' && item.terminals && item.terminals.length) {
              infoObj = item.terminal
            }

            if(headCell.id === 'carrier_details' && item.carrier_alias && infoObj) {
              infoObj["carrier_alias"] = item.carrier_alias
            }

            return (
              <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                {
                  infoObj
                    ?
                    <Tooltip
                      title={tooltipContent(headCell.id, infoObj)}
                      arrow
                      placement='left'
                      classes={{
                        tooltip: 'tooltip',
                      }}
                    >
                      <InfoIcon className='table-info-icon' />
                    </Tooltip>
                    : '-'
                }
              </TableCell>
            )
          }

          if (headCell.id === 'action') {
            if(item.archived) {
              return (
                <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                  <label>Archived</label>
                </TableCell>
              )
            } else {
              return (
                <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                  <EditIcon className='table-edit-icon' onClick={() => handleEdit(item)} />
                  <DeleteIcon className='table-delete-icon' onClick={() => handleDelete(item)} />
                </TableCell>
              )
            }
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={_.get(item, headCell.accessor)} />);
        })
      }
    </TableRow>
  );
};

export default Row;
