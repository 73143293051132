import React from 'react';
import { Switch } from '@mui/material';

const SwitchComponent = ({ color, disabled, size, checked, onChange }) => {
  return (
    <Switch
      color={color}
      checked={checked}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};

export default SwitchComponent;
