import http from '../httpConfig';

const getCustomerIds = ({ token, order, orderBy, page, perPage, carrierIdFilter }) => {
  let url = `/customerIds?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}`
  if(carrierIdFilter) {
    url += `&carrierId=${carrierIdFilter}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const exportCustomerIds = ({ token, carrierId }) => {
  return http.get(`/customerIds/export?carrierId=${carrierId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const importCustomerIds = ({ token, payload }) => {
  return http.post(`/customerIds/import`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};


const CustomerIdService = {
  getCustomerIds,
  exportCustomerIds,
  importCustomerIds
};

export default CustomerIdService;