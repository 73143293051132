import React, { useRef, useEffect, useState } from 'react';
import { TableCell, Tooltip } from '@mui/material';
import clsx from 'clsx';

const ElipsisCell = ({
  node, text, align, width, parentRef, className, rootClassName,
}) => {
  const textElementRef = useRef(null);
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    // @ts-ignore
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
  }, [text]);

  return (
    <TableCell align={align} style={{ width }} className={clsx(rootClassName)} ref={parentRef}>
      <Tooltip
        title={text || ''}
        arrow
        disableHoverListener={!hoverStatus}
        classes={{
          tooltip: 'tooltip',
        }}
      >
        <div className={clsx('elipsis', className)} ref={textElementRef} style={{ maxWidth: width.replace('%', 'vw') }}>
          {node || text}
        </div>
      </Tooltip>
    </TableCell>
  );
};

export default ElipsisCell;
