export const dateFormat = (date) => {
  return new Date(date).toLocaleString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
} 

export const findStartEndTimeFromBucket = (bucketType, startDate, endDate) => {
  const currentTime = new Date();
  let apiBucketType = bucketType;
  let endTime = currentTime.getTime();
  let startTime;

  if (bucketType === 'day') {
    startTime = currentTime.setDate(currentTime.getDate() - 1);
  } else if (bucketType === 'month') {
    startTime = currentTime.setDate(currentTime.getDate() - 30);
  } else if (bucketType === 'year') {
    startTime = currentTime.setDate(currentTime.getDate() - 365);
  } else {
    startTime = currentTime.setDate(currentTime.getDate() - 7);
  }

  if(startDate && endDate) {
    apiBucketType = 'custom'
    startTime = new Date(startDate).getTime();

    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endTime = new Date(endDate).getTime();
  }

  return {
    apiBucketType,
    startTime,
    endTime
  }
}

export const capitalizeFirstLetter = (str) => {
  if (!str) {
    return '';
  }
  return str.replace(/\b\w+/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};