import React, { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom"
import CopyIcon from "../images/copy.png";
import CopyToClipboard from "react-copy-to-clipboard";
import { API_URL } from "../utils/appConstants"
import Spinner from "../components/circularProgress";
import UserService from "../services/user.service";

function Dashboard({ user, loggedInUser }) {
  const navigate = useNavigate();

  const [copyState, setCopyState] = useState({
    apiKey: false,
    cognitoToken: false,
    tandemUrl: false,
  });
  const [showToken, setShowToken] = useState(false);
  const [apiKey, setApiKey] = useState();
  const [apiKeySaveLoading, setApiKeySaveLoading] = useState();

  const regenerateKey = () => {
    setApiKey(uuidv4())
  }

  const saveNewApiKey = useCallback(() => {
    setApiKeySaveLoading(true)
    const payload = { role_id: loggedInUser.role_id._id, user_id: loggedInUser._id }

    if (apiKey) {
      payload['apiKey'] = apiKey
    }

    UserService.editUser(user?.signInUserSession?.accessToken?.jwtToken, payload)
      .then(() => {
        setApiKeySaveLoading(false)
      })
  }, [JSON.stringify(loggedInUser), apiKey])

  // const validated = localStorage.getItem("validated")

  // useEffect(() => {
  //   if (user.attributes['custom:role'] === 'superadmin') {
  //     navigate('/home/domains')
  //   }
  // }, [navigate, user])

  return (
    <div className="root">
      {
        (loggedInUser?.role_id?.role_name === 'fulluser' || loggedInUser?.role_id?.role_name === 'limiteduser') && <div>
          <span>{apiKey}</span>
          <br />
          <button className="btn dashboard-regenerate-api-key" onClick={regenerateKey}>
            Regenerate API Key
          </button>
          <button className={`btn add-domain-btn ${(apiKeySaveLoading || !apiKey) && 'disabled'}`} disabled={apiKeySaveLoading || !apiKey} onClick={saveNewApiKey}>
            {apiKeySaveLoading ? <Spinner size={22} color="white" /> : "Save"}
          </button>
        </div>
      }


      <div className="container">
        <h3>Welcome to the Tandem API Sandbox.</h3>
        <br />
        <h4>Overview</h4>
        <label>The Sandbox contains API endpoints, payload structures, responses, schemas, and any cross-reference (XREF) data that has been created for your domain. You can find a dashboard displaying all the orders relative to your domain along with the XREF order outputs and the order statuses in the “Summary” tab in the menu on the left.</label>
        <br />
        <h4>Authentication</h4>
        <ul>
          <li>Once logged in to the Sandbox, you will be automatically authenticated to test the endpoints in the Swagger tab.</li>
          <li>If you would like to programmatically test our endpoints, you will leverage our POST users/login endpoint.</li>
        </ul>

        <h4>Tandem Statuses</h4>
        <label>Below you will find a list of statuses that Tandem applies to an order depending on where the order is in its lifecycle.</label>
        <br />
        <table border={1} className="info-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Definition</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>NEW</td>
              <td>Tandem accepts the order from the carrier.</td>
            </tr>
            <tr>
              <td>PROCESSING</td>
              <td>Tandem is processing the order through XREF.</td>
            </tr>
            <tr>
              <td>ON_HOLD</td>
              <td>The order has been put on hold.</td>
            </tr>
            <tr>
              <td>EXPIRATION_DATE_PAST</td>
              <td>The order has been put on hold due to expiration date is past.</td>
            </tr>
            <tr>
              <td>TERMINAL ACCEPTED</td>
              <td>The order has been sent to the terminal, awaiting terminal response.</td>
            </tr>
            <tr>
              <td>SUCCESS</td>
              <td>The terminal has accepted the order.</td>
            </tr>
            <tr>
              <td>COMPLETED</td>
              <td>The order has been completed by the driver.</td>
            </tr>
            <tr>
              <td>TANDEM ALERT</td>
              <td>The terminal API declined the order.</td>
            </tr>
            <tr>
              <td>CROSS REF ERROR</td>
              <td>A field lookup failed, Tandem holds the order.</td>
            </tr>
            <tr>
              <td>UPDATING</td>
              <td>Tandem is updating an order and running the order through the XREF tables based on new data received.</td>
            </tr>
            <tr>
              <td>EXPIRED</td>
              <td>The order has passed the expiration date and will no longer be displayed at the terminal for the driver.</td>
            </tr>
            <tr>
              <td>CANCEL</td>
              <td>The carrier has sent a Cancel request.</td>
            </tr>
            <tr>
              <td>CANCELING</td>
              <td>Tandem has sent the Cancel request to the terminal, awaiting response from the terminal.</td>
            </tr>
            <tr>
              <td>CANCELED</td>
              <td>The Terminal has successfully canceled the order.</td>
            </tr>
          </tbody>
        </table>

        {/* <br /> */}

        {/* <h4>Tandem Field Definition and Default Validation</h4>
        <br />
        <table border={1} className="info-table">
          <thead>
            <tr>
              <th>Field Name</th>
              <th>Definition</th>
              <th>Validation Rule</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Shipment Date</td>
              <td>Date the order is scheduled for pickup.</td>
              <td>None.</td>
            </tr>
            <tr>
              <td>Order Date</td>
              <td>Date the order is created with the Carrier.</td>
              <td>None.</td>
            </tr>
            <tr>
              <td>Protocol Version</td>
              <td>Tandem’s Protocol version.</td>
              <td>Provided by Tandem during onboarding. Default is 7.</td>
            </tr>
            <tr>
              <td>Terminal Control Number</td>
              <td>Filling terminal ID.</td>
              <td>IRS Registered Terminal Number.</td>
            </tr>
            <tr>
              <td>Customer ID</td>
              <td>Customer receiving the load.</td>
              <td>XREF completed.</td>
            </tr>
            <tr>
              <td>Driver Name</td>
              <td>Driver picking up the load.</td>
              <td>XREF completed.</td>
            </tr>
            <tr>
              <td>Shipper ID</td>
              <td>Supplier of the product.</td>
              <td>XREF completed.</td>
            </tr>
            <tr>
              <td>Vehicle ID</td>
              <td>Primary vehicle ID.</td>
              <td>XREF completed.</td>
            </tr>
            <tr>
              <td>Trailer ID</td>
              <td>Additional trailer being loaded (if applicable).</td>
              <td>XREF completed.</td>
            </tr>
            <tr>
              <td>Effective Date</td>
              <td>Date the order becomes valid and available for a driver to load.</td>
              <td>Date Required.</td>
            </tr>
            <tr>
              <td>Expiration Date</td>
              <td>Date the order expires, becoming unavailable for the driver to select at the terminal.</td>
              <td>Will default to effective date +72 hours if the field is sent as null.</td>
            </tr>
            <tr>
              <td>Order Number</td>
              <td>Internal carrier order number.</td>
              <td>None.</td>
            </tr>
            <tr>
              <td>Persistent Order</td>
              <td>Tandem does not currently support persistent loads.</td>
              <td>‘N’ Flag Required.</td>
            </tr>
            <tr>
              <td>Product Code</td>
              <td>Product being procured.</td>
              <td>XREF completed.</td>
            </tr>
            <tr>
              <td>Product Quantity</td>
              <td>Gallons.</td>
              <td>Tandem currently allows drivers to enter the quantity.</td>
            </tr>
          </tbody>
        </table> */}

        <div className="container-card">
          <div className="copy-details-root cognito-copy-details-root">
            <div className="cognito-token">
              <label>
                <b>Cognito Token:</b>
              </label>
              <button
                className="btn btn--card"
                onClick={() => setShowToken((prev) => !prev)}
              >
                {showToken ? "Hide" : "Show"}
              </button>
              <CopyToClipboard
                text={user?.signInUserSession?.accessToken?.jwtToken}
                onCopy={() =>
                  setCopyState({
                    apiKey: false,
                    cognitoToken: true,
                    tandemUrl: false,
                  })
                }
              >
                <img
                  src={CopyIcon}
                  className={copyState.cognitoToken ? "active-copy" : ""}
                  title="Copy Cognito Token"
                  alt="Copy Cognito Token"
                />
              </CopyToClipboard>
            </div>
            <div>
              <span className="token-text">
                {showToken
                  ? user?.signInUserSession?.accessToken?.jwtToken
                  : ""}
              </span>
            </div>
          </div>

          <div className="copy-details-root">
            <label>
              <b>Tandem URL:</b> {API_URL}
            </label>
            <CopyToClipboard
              text={API_URL}
              onCopy={() =>
                setCopyState({
                  apiKey: false,
                  cognitoToken: false,
                  tandemUrl: true,
                })
              }
            >
              <img
                src={CopyIcon}
                className={copyState.tandemUrl ? "active-copy" : ""}
                title="Tandem URL"
                alt="Tandem URL"
              />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;