import http from '../httpConfig';

const getVehicles = ({token, order, orderBy, page, perPage, carrierIdFilter}) => {
  let url = '/vehicles';
  if(order && orderBy && page && perPage) {
    url = `${url}?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}&carrierId=${carrierIdFilter}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getVehiclesByCarrier = ({token, carrierId}) => {
  let url = '/vehicles/carrier';
  if(carrierId) {
    url = `${url}?carrierId=${carrierId}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const addVehicle = (token, payload) => {
  return http.post('/vehicles', payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const exportVehicles = ({ token, carrierId }) => {
  return http.get(`/vehicles/export?carrierId=${carrierId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const importVehicles = ({ token, payload }) => {
  return http.post(`/vehicles/import`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const VehicleService = {
  getVehicles,
  addVehicle,
  getVehiclesByCarrier,
  exportVehicles,
  importVehicles
};

export default VehicleService;