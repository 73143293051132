import { useState, useMemo } from 'react';

const useSorting = ({ defaultOrder, defaultOrderBy }) => {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  return useMemo(() => {
    return {
      order, orderBy, setOrder, setOrderBy,
    };
  }, [order, orderBy, setOrder, setOrderBy]);
};

export default useSorting;
