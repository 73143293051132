import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import Spinner from '../../circularProgress';

const LoadingRow = ({ colSpan }) => {
  return (
    <TableRow className="table-body-row">
      <TableCell className="loading-row" colSpan={colSpan}>
        <Spinner size={30} />
      </TableCell>
    </TableRow>
  );
};

export default LoadingRow;
