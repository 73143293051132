import React from 'react';
import { TableRow, TableCell } from '@mui/material';

const EmptyRow = ({ text, colSpan }) => {
  return (
    <TableRow className="table-body-row">
      <TableCell className="empty-row" colSpan={colSpan}>
        <div>{text}</div>
      </TableCell>
    </TableRow>
  );
};

export default EmptyRow;
