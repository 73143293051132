import http from '../httpConfig';

const getDrivers = ({token, order, orderBy, page, perPage, carrierIdFilter}) => {
  let url = '/drivers';
  if(order && orderBy && page && perPage) {
    url = `${url}?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}&carrierId=${carrierIdFilter}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const addDriver = (token, payload) => {
  return http.post('/drivers', payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const exportDrivers = ({ token, carrierId }) => {
  return http.get(`/drivers/export?carrierId=${carrierId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const importDrivers = ({ token, payload }) => {
  return http.post(`/drivers/import`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const DriverService = {
  getDrivers,
  addDriver,
  exportDrivers,
  importDrivers
};

export default DriverService;