import React from 'react';
import Select from 'react-select';

const Dropdown = ({
  options, disabled, onChange, searchable, value, className,useCustomStyles
}) => {
  return (
    <Select
      menuPortalTarget={document.body}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      classNamePrefix={useCustomStyles && 'custom-select'}
      defaultValue={""}
      isDisabled={disabled}
      isClearable={false}
      isSearchable={!!searchable}
      options={options}
      onChange={onChange}
      value={value}
      menuPlacement={'auto'}
      className={className}
    />
  );
};

export default Dropdown;
