import React, { useState, useCallback, useMemo } from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Bar } from 'recharts';
import clsx from "clsx";
import { toast } from 'react-toastify';
import moment from "moment";
import Switch from '@mui/material/Switch';
import DatePicker from "react-datepicker";
import Chart from "../components/charts/barChart";
import Table from "../components/Table";
import ReplayOrder from "../components/popup";
import Spinner from "../components/circularProgress";
import SummaryRow from "../components/summary/row";
import OrderService from "../services/order.service";
import useFetch from "../hooks/useFetch";
import usePagination from "../hooks/usePagination";
import useSorting from "../hooks/useSorting";
import { SortOrder } from "../utils/appConstants";

function Summary({ user, actAsDomainIdMemoised, loggedInUser }) {
  const [filterText, setFilterText] = useState("");
  const [driverNameError, setDriverNameError] = useState("");
  const [replayLoading, setReplayLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [filters, setFilters] = useState("");
  const [chartDuration, setChartDuration] = useState("week");
  const [chartDurationForList, setChartDurationForList] = useState("week");
  const [chartData, setChartData] = useState([]);
  const [filterWithList, setFilterWithList] = useState(true);
  const [orderReplayDetails, setOrderReplayDetails] = useState({});
  const [reload, setReload] = useState(true);
  const [newValues, setNewValues] = useState({
    tcn: '',
    driverName: '',
    customerId: '',
    vehicleTypeCode: '',
    shipperId: ''
  });
  const [productCodes, setProductCodes] = useState([]);
  const [dates, setDates] = useState({
    start: null,
    end: null
  });
  const [datesForList, setDatesForList] = useState({
    start: null,
    end: null
  });

  const [datesForApi, setDatesForApi] = useState({
    start: null,
    end: null
  });
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = useCallback(() => {
    setOpen(false);
    setNewValues({
      tcn: '',
      driverName: '',
      customerId: '',
      vehicleTypeCode: '',
      shipperId: ''
    })
    setProductCodes([])
    setOrderReplayDetails({})
  }, [])

  const {
    order, orderBy, setOrder, setOrderBy,
  } = useSorting({ defaultOrder: SortOrder.desc, defaultOrderBy: 'created_date' });
  const {
    page, perPage, setPage, setPerPage,
  } = usePagination({ defaultPage: 1, defaultPerPage: 10 });

  const { data: orders, total, loading: ordersLoading } = useFetch({
    service: OrderService,
    func: 'getOrders',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      order, orderBy, page, perPage,
      filterText: filters,
      chartDuration: chartDurationForList,
      startDate: datesForApi.start, 
      endDate: datesForApi.end,
      actAsDomainId: actAsDomainIdMemoised
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, reload, order, orderBy, page, perPage, filters, chartDurationForList, actAsDomainIdMemoised, datesForApi.start, datesForApi.end],
  });

  const handleChartData = useCallback((chartData) => {
    const tempChartData = chartData.data.data.map((item) => {
      return {
        ...item,
        dataKey: moment(item.date).format(item.format)
      }
    })
    setChartData(tempChartData)
  }, [setChartData])

  const { loading: chartLoading } = useFetch({
    service: OrderService,
    func: 'getChartData',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      bucketType: chartDuration,
      startDate: datesForApi.start, 
      endDate: datesForApi.end,
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, chartDuration, datesForApi.start, datesForApi.end],
    onSuccess: handleChartData
  });

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
  }

  const handleChartDurationChange = useCallback((value) => {
    setChartDuration(value);
    setDates({
      start: null,
      end: null
    })
    
    if (filterWithList) {
      setChartDurationForList(value)
      setDatesForList({
        start: null,
        end: null
      })
      setPage(1)
    }
  }, [filterWithList])

  const handleFilterOrders = useCallback(() => {
    setFilters(filterText)
    setPage(1)
  }, [filterText])

  const handleToggleChange = (event) => {
    setFilterWithList(event.target.checked);
  };

  const chartBars = [
    <Bar key="noStatus" dataKey="noStatus" barSize={10} stackId="a" fill={"#00BBE7"} />,
    <Bar key="newStatus" dataKey="newStatus" barSize={10} stackId="a" fill={"#5C950B"} />,
    <Bar key="processingStatus" dataKey="processingStatus" barSize={10} stackId="a" fill={"#FEB42F"} />,
    <Bar key="holdingStatus" dataKey="holdingStatus" barSize={10} stackId="a" fill={"#008080"} />,
    <Bar key="holdingExpiredDateStatus" dataKey="holdingExpiredDateStatus" barSize={10} stackId="a" fill={"#b6a934"} />,
    <Bar key="completedStatus" dataKey="completedStatus" barSize={10} stackId="a" fill={"#B56917"} />,
    <Bar key="successStatus" dataKey="successStatus" barSize={10} stackId="a" fill={"#8ACE33"} />,
    <Bar key="cancelStatus" dataKey="cancelStatus" barSize={10} stackId="a" fill={"#953A0B"} />,
    <Bar key="errorStatus" dataKey="errorStatus" barSize={10} stackId="a" fill={"#F18181"} />,
    <Bar key="expiredStatus" dataKey="expiredStatus" barSize={10} stackId="a" fill={"#F53B3B"} />
  ];

  const exportOrders = useCallback(() => {
    setExportLoading(true)
    OrderService.exportOrders({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      domainId: loggedInUser?.domain_id,
      actAsDomainIdMemoised,
      bucketType: chartDuration,
      startDate: datesForApi.start, 
      endDate: datesForApi.end,
      filterText: filters,
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const exportLinkElement = document.createElement('a');

        exportLinkElement.hidden = true;
        exportLinkElement.download = `${loggedInUser?.domain_id}.csv`;
        exportLinkElement.href = url;
        exportLinkElement.text = "downloading...";

        document.body.appendChild(exportLinkElement);
        exportLinkElement.click();
        setExportLoading(false)
        exportLinkElement.remove();
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong", { icon: false });
        setExportLoading(false)
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken, actAsDomainIdMemoised, JSON.stringify(loggedInUser), filters, chartDuration, datesForApi.start, datesForApi.end])

  const headCells = [
  //   {
  //   id: 'replay',
  //   accessor: 'replay',
  //   align: 'left',
  //   disablePadding: false,
  //   sortEnabled: false,
  //   label: "Action",
  //   width: "3%",
  // }, 
 {
    id: 'tandem_id',
    accessor: 'tandem_id',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Tandem Id",
    width: "8%",
  }, {
    id: 'order_status',
    accessor: 'order_status',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Order Status",
    width: "10%",
  }, {
    id: 'created_date',
    accessor: 'created_date',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Created Date",
    width: "8%",
  }, {
    id: 'metered_load.driver_name',
    accessor: 'metered_load.driver_name',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Driver Name",
    width: "10%",
  }, {
    id: 'metered_load.terminal_control_number',
    accessor: 'metered_load.terminal_control_number',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Terminal Control Number",
    width: "10%",
  }, {
    id: 'metered_load.customer_id',
    accessor: 'metered_load.customer_id',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Customer Id",
    width: "6%",
  }, {
    id: 'metered_load.shipper_id',
    accessor: 'metered_load.shipper_id',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Shipper Id",
    width: "6%",
  }, {
    id: 'metered_load.order_number',
    accessor: 'metered_load.order_number',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Order Number",
    width: "6%",
  }, {
    id: 'order_update_token_expired_at',
    accessor: 'order_update_token_expired_at',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Expiration Date",
    width: "5%",
  }, {
    id: 'metered_load.effective_date',
    accessor: 'metered_load.effective_date',
    align: 'left',
    disablePadding: false,
    sortEnabled: true,
    label: "Effective Date",
    width: "5%",
  }, {
    id: 'details',
    accessor: 'details',
    align: 'center',
    disablePadding: false,
    sortEnabled: false,
    label: "Order Details",
    width: "5%",
  }]

  const onReplyRowClick = useCallback((orderId) => {
    const order = orders.find(orderItem => orderItem._id === orderId)
    setOrderReplayDetails(order || {})
    openModal();
  }, [JSON.stringify(orders)])

  const handleNewValuesChange = (event, key) => {
    setNewValues(prev => {
      return {
        ...prev,
        [key]: event.target.value
      }
    })
  }

  const handleNewValuesProductCodeChange = useCallback((event, from) => {
    const existedValue = productCodes.find(item => item.from === from);
    let tempProductCodes = []

    if (existedValue) {
      tempProductCodes = productCodes.map(productCode => {
        if (productCode.from === from) {
          return {
            ...productCode,
            to: event.target.value
          }
        } else {
          return productCode;
        }
      })
    } else {
      tempProductCodes = [...productCodes, {
        from,
        to: event.target.value
      }]
    }

    setProductCodes(tempProductCodes)
  }, [JSON.stringify(productCodes)])

  const payload = useMemo(() => {
    const tempPayload = {
      id: orderReplayDetails._id
    }

    if (newValues.tcn) {
      tempPayload["terminal_control_number"] = newValues.tcn.trim();
    }

    if (newValues.driverName) {
      tempPayload["driver_name"] = newValues.driverName.trim();
    }

    if (newValues.customerId) {
      tempPayload["customer_id"] = newValues.customerId.trim();
    }

    if (newValues.shipperId) {
      tempPayload["shipper_id"] = newValues.shipperId.trim();
    }

    if (productCodes && productCodes.length) {
      tempPayload["product_codes"] = productCodes.map(productCode => {
        return {
          ...productCode,
          to: productCode.to.trim()
        }
      });
    }

    return tempPayload;
  }, [
    JSON.stringify(orderReplayDetails),
    JSON.stringify(newValues),
    JSON.stringify(productCodes),
    handleNewValuesChange
  ])

  const handleReplayOrder = useCallback(() => {
    setReplayLoading(true);

    OrderService.replayOrders({
      token: user?.signInUserSession?.accessToken?.jwtToken,
      payload: payload
    })
      .then(response => {
        setReplayLoading(false)
        setReload(prev => !prev)
        closeModal()
        toast.success("Order replayed successfully.", { icon: false });
      })
      .catch((err) => {
        toast.error(err.message || "Something went wrong", { icon: false });
        setReplayLoading(false)
      })
  }, [
    user?.signInUserSession?.accessToken?.jwtToken,
    JSON.stringify(payload)
  ])

  const replayModalTitle = "Replay Order";

  const replayModalContent = useMemo(() => {
    const meteredLoad = orderReplayDetails.metered_load || {};
    const translatedData = orderReplayDetails.translated_data || {};

    return (
      <div className="replay-order-root">
        <div className="replay-order">
          <div className="replay-left">
            <label className="title">Current Values</label>
            <div className="current-values-form">
              <div className="form-field-box">
                <label>Terminal Identifier</label>
                <input value={meteredLoad.terminal_control_number || meteredLoad.terminal_id} disabled className="disabled" />
                {translatedData?.terminal_control_number?.error && <label className="d-error">{translatedData.terminal_control_number.error}</label>}
              </div>
              <div className="form-field-box">
                <label>Driver Name</label>
                <input value={translatedData?.driver_name?.from} disabled className="disabled" />
                {translatedData?.driver_name?.error && <label className="d-error">{translatedData.driver_name.error}</label>}
              </div>
              <div className="form-field-box">
                <label>Customer ID</label>
                <input value={translatedData?.customer_id?.from} disabled className="disabled" />
                {translatedData?.customer_id?.error && <label className="d-error">{translatedData.customer_id.error}</label>}
              </div>
              <div className="form-field-box">
                <label>Shipper ID</label>
                <input value={translatedData?.shipper_id?.from} disabled className="disabled" />
                {translatedData?.shipper_id?.error && <label className="d-error">{translatedData.shipper_id.error}</label>}
              </div>
            </div>
          </div>
          <div className="break-line"></div>
          <div className="replay-right">
            <label className="title">New Values</label>
            <div className="new-values-form">
              <div className="form-field-box">
                <label>Terminal Identifier</label>
                <input value={newValues.tcn} onChange={(e) => handleNewValuesChange(e, 'tcn')} placeholder="Enter Terminal Identifier" />
              </div>
              <div className={clsx("form-field-box", translatedData?.driver_name?.error && 'margin-bottom')}>
                <label>Driver Name</label>
                <input value={newValues.driverName} onChange={(e) => handleNewValuesChange(e, 'driverName')} placeholder="Enter Driver Name" />
                {driverNameError && <label className="d-error">{driverNameError}</label>}
              </div>
              <div className={clsx("form-field-box", translatedData?.customer_id?.error && 'margin-bottom')}>
                <label>Customer ID</label>
                <input value={newValues.customerId} onChange={(e) => handleNewValuesChange(e, 'customerId')} placeholder="Enter Customer ID" />
              </div>
              <div className={clsx("form-field-box", translatedData?.shipper_id?.error && 'margin-bottom')}>
                <label>Shipper ID</label>
                <input value={newValues.shipperId} onChange={(e) => handleNewValuesChange(e, 'shipperId')} placeholder="Enter Shipper ID" />
              </div>
            </div>
          </div>
        </div>
        <div className="product-codes">
          <label className="title">Product Codes</label>
          {
            (translatedData.product_code || []).map((productCode, index) => {
              const existedNewData = productCodes.find(item => item.from === productCode.from)
              return (
                <div key={index} className="product-code-main">
                  <div className="product-code-child form-field-box">
                    <input value={productCode.from} disabled className="disabled" />
                    {productCode?.error && <label className="d-error">{productCode.error}</label>}
                  </div>
                  <div className="product-code-child">
                    <ArrowForwardIcon />
                  </div>
                  <div className="product-code-child">
                    <input value={existedNewData ? existedNewData.to : ""} onChange={(e) => handleNewValuesProductCodeChange(e, productCode.from)} placeholder="Enter Product Code" />
                  </div>
                </div>
              )
            })
          }
          {
            translatedData?.shipper_id?.error && (!translatedData.product_code || !translatedData.product_code.length) && <div className="form-field-box">
              <label className="d-error">Product codes are not showing because of shipper id not translated.</label>
            </div>
          }
        </div>
      </div>
    )
  }, [
    JSON.stringify(orderReplayDetails),
    JSON.stringify(newValues),
    JSON.stringify(productCodes),
    driverNameError,
    handleNewValuesChange
  ])

  const btnDisabled = useMemo(() => {
    let disabled = false;
    let driverError = '';
    if (
      !newValues.tcn.trim() &&
      !newValues.driverName.trim() &&
      !newValues.customerId.trim() &&
      !newValues.shipperId.trim() &&
      !newValues.vehicleTypeCode.trim() &&
      (!productCodes || !productCodes.length || (productCodes && productCodes.every(item => !item.to.trim())))
    ) {
      disabled = true;
    }

    if(newValues.driverName) {
      const driverNameTrimmed = newValues.driverName.trim()
      if(driverNameTrimmed.includes(',')) {
        const [lastName, firstName] = driverNameTrimmed.split(/,(.*)/s);
        if(!firstName || !lastName) {
          driverError = "Provided driver name is not valid, It should have firstname and lastname."
          disabled = true;
        }
      } else {
        const [firstName, lastName] = driverNameTrimmed.split(/ (.*)/s);
        if(!firstName || !lastName) {
          driverError = "Provided driver name is not valid, It should have firstname and lastname."
          disabled = true;
        }
      }
    }
    
    setDriverNameError(driverError)
    return disabled;
  }, [
    JSON.stringify(newValues),
    JSON.stringify(productCodes),
    setDriverNameError
  ])

  const replayModalAction = useMemo(() => {
    let btnContent;

    if (replayLoading) {
      btnContent = <Spinner size={22} color="white" />
    } else {
      btnContent = "Replay"
    }

    return (
      <div className="add-domain-btn-div">
        <button className={`btn add-domain-btn ${btnDisabled && 'disabled'}`} disabled={btnDisabled} onClick={handleReplayOrder}>
          {btnContent}
        </button>
      </div>
    )
  }, [btnDisabled, replayLoading, handleReplayOrder])

  const handleDatesChange = useCallback((date, dateType) => {
    setDates(dates => {
      return {
        ...dates,
        [dateType]: date
      }
    })

    if (filterWithList) {
      setDatesForList(dates => {
        return {
          ...dates,
          [dateType]: date
        }
      })
    }
  }, [filterWithList])

  const onFilterClick = useCallback(() => {
    setChartDuration("")
    setChartDurationForList("")
    setDatesForApi({
      start: dates.start,
      end: dates.end
    })
    setPage(1)
  }, [dates.start, dates.end])

  return (
    <div className="summary-root">
      <div className="table-layout orders-table">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Summary</h3>
          <div className="chart-filters">
            <div className="default-root">
              <label className="past-label">Past - </label>
              <div className="default">
                <div className={clsx("filter-box", chartDuration === 'day' && 'selected')} onClick={() => handleChartDurationChange('day')}>
                  <label>24H</label>
                </div>
                <div className={clsx("filter-box", chartDuration === 'week' && 'selected')} onClick={() => handleChartDurationChange('week')}>
                  <label>1W</label>
                </div>
                <div className={clsx("filter-box", chartDuration === 'month' && 'selected')} onClick={() => handleChartDurationChange('month')}>
                  <label>1M</label>
                </div>
                <div className={clsx("filter-box", chartDuration === 'year' && 'selected')} onClick={() => handleChartDurationChange('year')}>
                  <label>1Y</label>
                </div>
              </div>
            </div>
            <div className="date-range-root">
              <DatePicker
                selected={dates.start}
                onChange={(date, event) => handleDatesChange(date, 'start')}
                placeholderText="Start Date"
                maxDate={dates.end || new Date()}
              />
              <DatePicker
                selected={dates.end}
                onChange={(date, event) => handleDatesChange(date, 'end')}
                placeholderText="End Date"
                maxDate={new Date()}
                minDate={dates.start}
              />
              <button className={`btn date-filter-btn ${(!dates.start || !dates.end) && 'disabled'}`} disabled={!dates.start || !dates.end} onClick={onFilterClick}>Filter</button>
            </div>
          </div>
        </div>
        <div className="toggle-and-legends">
          <div className="toggle">
            <label className="toggle-title">Filter with data table</label>
            <Switch
              checked={filterWithList}
              onChange={handleToggleChange}
              classes={{
                track: filterWithList && 'checked'
              }}
            />
          </div>
          <div className="legends">
            <div className="legend">
              <div className="dot complementory"></div>
              <span className="text">NEW</span>
            </div>
            <div className="legend">
              <div className="dot primary"></div>
              <span className="text">NO STATUS</span>
            </div>
            <div className="legend">
              <div className="dot processing"></div>
              <span className="text">PROCESSING</span>
            </div>
            <div className="legend">
              <div className="dot holding"></div>
              <span className="text">HOLDING</span>
            </div>
            <div className="legend">
              <div className="dot holdingExpDate"></div>
              <span className="text">EXPIRATION_DATE_PAST</span>
            </div>
            <div className="legend">
              <div className="dot completed"></div>
              <span className="text">COMPLETED</span>
            </div>
            <div className="legend">
              <div className="dot complementory-2"></div>
              <span className="text">CANCELLING/CANCELLED</span>
            </div>
            <div className="legend">
              <div className="dot success"></div>
              <span className="text">SUCCESS</span>
            </div>
            <div className="legend">
              <div className="dot error"></div>
              <span className="text">ERROR</span>
            </div>
            <div className="legend">
              <div className="dot expired"></div>
              <span className="text">EXPIRED</span>
            </div>
          </div>
        </div>
        <div className="summary-charts">
          {
            chartLoading ? <Spinner size={35} /> : <Chart bars={chartBars} data={chartData} labels={{ xaxis: 'Date/Time', yaxis: 'Orders' }} />
          }
        </div>
      </div>
      <div className="table-layout orders-table">
        <div className="table-layout-header">
          <h3 className="table-layout-title">Orders</h3>
          <div className="filters">
            <input value={filterText} onChange={handleFilterTextChange} placeholder="Search by keywords..." />
            <button className={`btn filter-order-btn`} disabled={ordersLoading} onClick={handleFilterOrders}>
              {
                ordersLoading ? <Spinner size={22} color="white" /> : `Search`
              }
            </button>
            <button className={`btn`} onClick={exportOrders}>
              {
                exportLoading ? <Spinner size={22} color="white" /> : 'Export'
              }
            </button>
          </div>
        </div>
        <div className="orders-table-main">
          <Table
            data={orders}
            loading={ordersLoading}
            total={total}
            perPage={perPage}
            page={page}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            setPage={setPage}
            setPerPage={setPerPage}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            row={SummaryRow}
            rowProps={{
              user,
              onReplyRowClick
            }}
            noDataFoundMessage={`No Orders Found`}
          />
        </div>
      </div>
      <ReplayOrder
        open={open}
        title={replayModalTitle}
        content={replayModalContent}
        action={replayModalAction}
        paperClassName={"add-driver-paper"}
        maxWidth={'lg'}
        onClose={closeModal}
      />
    </div>
  )
}

export default Summary;