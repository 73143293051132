import React from 'react';
import AsyncSelect from 'react-select/async'

const AutocompleteComponent = ({
  loadOptions, defaultOptions, onChange, value, inputValue, onInputChange, isMulti
}) => {
  return (
    <AsyncSelect
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
      isMulti={isMulti === "false" ? false : true}
      cacheOptions 
      defaultOptions={defaultOptions}
      isClearable={false}
      closeMenuOnSelect={false}
      inputValue={inputValue}
      onInputChange={inputVal => onInputChange(inputVal)}
      onMenuClose={() => onInputChange(inputValue)}
      onFocus={() => onInputChange(inputValue)}
    />
  );
};

export default AutocompleteComponent;
