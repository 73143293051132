import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import ElipsisCell from '../Table/Cells/ElipsisCell';
import EditIcon from '@mui/icons-material/Edit';
import { dateFormat } from '../../utils/methods';
import _ from "lodash";

const Row = ({ item, headCells, handleEditIconClick }) => {
  return (
    <TableRow className="table-body-row">
      {
        headCells.map((headCell) => {
          if (headCell.id === 'created_date' || headCell.id === 'last_activity_time') {
            const value = _.get(item, headCell.accessor);
            return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={value ? dateFormat(new Date(value).toISOString()) : ''} />);
          }

          if (headCell.id === 'action') {
            return (
              <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                <EditIcon className='table-edit-icon' onClick={() => handleEditIconClick(item)} />
              </TableCell>
            )
          }

          return (<ElipsisCell key={headCell.id} width={headCell.width} align={headCell.align} text={_.get(item, headCell.accessor)} />);
        })
      }
    </TableRow>
  );
};

export default Row;
