import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ApiKeyModal from "../popup";
import LeftSection from "./leftSection";
import RightSection from "./rightSection";
import useFetch from "../../hooks/useFetch";
import UserService from "../../services/user.service";

const Layout = ({ children, user, signOut, hasAccess, handleActAsDomainIdChange, actAsDomainId }) => {
  const [open, setOpen] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const navigate = useNavigate();

  const [collapse, setCollapse] = useState(false);
  const [access, setAccess] = useState(true);

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const getApiKey = useCallback((token) => {
    UserService.getApiKey(token)
      .then(res => {
        if (res.data.data) {
          if(!res.data.data.isApiKeySeen) {
            openModal()
            setApiKey(res.data.data.apiKey)
          }
        }
      })
      .catch(() => {
      })
  }, [user?.signInUserSession?.accessToken?.jwtToken])

  const handleCollapse = () => {
    setCollapse(prev => !prev)
  }

  const setCollapseForMobile = () => {
    if(window.innerWidth <= 530) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }

  useEffect(() => {
    if (hasAccess && hasAccess.length && !hasAccess.includes(user.attributes['custom:role'])) {
      navigate('/home/no-access')
    }
  }, [hasAccess, user])

  useEffect(() => {
    setCollapseForMobile()
    getApiKey(user?.signInUserSession?.accessToken?.jwtToken)
  }, [user?.signInUserSession?.accessToken?.jwtToken])

  const handleUserDomainVerification = (response) => {
    if (!response.data.data.hasAccess) {
      setAccess(false);
      navigate('/home/no-domain-access')
    }
  }

  useFetch({
    service: UserService,
    func: 'verifyUserDomain',
    payload: {
      token: user?.signInUserSession?.accessToken?.jwtToken,
      email: user.attributes.email
    },
    dependency: [user?.signInUserSession?.accessToken?.jwtToken, user.attributes.email],
    onSuccess: (response) => handleUserDomainVerification(response)
  });

  const modalContent = useMemo(() => {
    return (
      <div className="form-field-box">
        <label>API Key <span className="note">(API key won't be visible once it is created, so copy it before leaving the popup.)</span></label>
        <span>{apiKey}</span>
      </div>
    )
  }, [apiKey])

  const modalAction = useMemo(() => {
    return (
      <div className="add-domain-btn-div">
        <button className="btn add-domain-btn" onClick={closeModal}>
          Close
        </button>
      </div>
    )
  }, [])


  return (
    <main className="layout">
      <LeftSection user={user} signOut={signOut} collapse={collapse} access={access} handleActAsDomainIdChange={handleActAsDomainIdChange} actAsDomainId={actAsDomainId} />
      <RightSection user={user} handleCollapse={handleCollapse} collapse={collapse}>
        {children}
      </RightSection>
      <ApiKeyModal
        open={open}
        title="API Key"
        content={modalContent}
        action={modalAction}
        onClose={closeModal}
      />
    </main>
  )
};

export default Layout;