import React from 'react';
import clsx from 'clsx';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ModalPopup = ({
  open, title, content, action, maxWidth, disableEscape, containerClassName, paperClassName, onClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth || 'md'}
      fullWidth
      classes={{
        root: 'd-dialog-root',
        container: clsx(containerClassName),
        paper: clsx(paperClassName),
      }}
      disableEscapeKeyDown={!!disableEscape}
    >
      {title && (
        <DialogTitle
          className="d-dialog-title"
        >
          {' '}
          {title}
          {' '}
        </DialogTitle>
      )}
      {content && (
        <DialogContent
          className="d-dialog-content"
        >
          {' '}
          {content}
          {' '}
        </DialogContent>
      )}
      {action && (
        <DialogActions
          className="d-dialog-action"
        >
          {' '}
          {action}
          {' '}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalPopup;