import http from '../httpConfig';
import { findStartEndTimeFromBucket } from "../utils/methods";

const getOrders = ({ token, order, orderBy, page, perPage, filterText, chartDuration, domainId, actAsDomainId, startDate, endDate, exportFilters }) => {
  let tempFilters = { filterText: filterText }
  if(chartDuration) {
    tempFilters['bucketType'] = chartDuration;
  }

  if(startDate && endDate) {
    tempFilters['bucketType'] = 'custom'
    tempFilters['startDate'] = new Date(startDate).getTime();

    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    tempFilters['endDate'] = new Date(endDate).getTime();
  }

  let url = `/orders/search?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}`;

  if(domainId) {
    url += `&domainId=${domainId}`
  }

  let payload = {};
  if(exportFilters && Object.keys(exportFilters).length >= 1) {
    payload = {exportFilters: exportFilters}
  } else {
    payload = {listFilters: tempFilters}
  }

  return http.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      actasdomainid: actAsDomainId
    }
  });
};

const getChartData = ({ token, bucketType, startDate, endDate }) => {
  const { apiBucketType, startTime, endTime } = findStartEndTimeFromBucket(bucketType, startDate, endDate)

  return http.get(`/orders/chart?bucketType=${apiBucketType}&startDate=${startTime}&endDate=${endTime}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const replayOrders = ({ token, payload }) => {
  return http.post(`/orders/replay`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

const exportOrders = ({ token, domainId, actAsDomainIdMemoised, bucketType, startDate, endDate, filterText, isManageOrders, exportFilters }) => {
  if(isManageOrders) {
    return http.post(`/orders/export?domainId=${domainId}`, { exportFilters: exportFilters}, {
      headers: {
        Authorization: `Bearer ${token}`,
        actasdomainid: actAsDomainIdMemoised
      }
    });
  } else {
    const { startTime, endTime } = findStartEndTimeFromBucket(bucketType, startDate, endDate)

    return http.post(`/orders/export?domainId=${domainId}&startDate=${startTime}&endDate=${endTime}&filterText=${filterText || ''}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        actasdomainid: actAsDomainIdMemoised
      }
    });
  }
};

const getCrossRefOrders = ({ token, order, orderBy, page, perPage, domainId, actAsDomainId, filterKey, searchText }) => {
  let url = `/orders/cross-ref?order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}`;

  if(domainId) {
    url += `&domainId=${domainId}`
  }

  if(filterKey) {
    url += `&filterKey=${filterKey}`
  }

  if(searchText) {
    url += `&searchText=${searchText}`
  }

  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      actasdomainid: actAsDomainId
    }
  });
};

const exportCrossRefOrders = ({ token, domainId, actAsDomainIdMemoised, filterKey, searchText }) => {
  return http.get(`/orders/export-cross-ref?domainId=${domainId}&filterKey=${filterKey}&searchText=${searchText}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      actasdomainid: actAsDomainIdMemoised
    }
  });
};

const OrderService = {
  getOrders,
  getCrossRefOrders,
  getChartData,
  replayOrders,
  exportOrders,
  exportCrossRefOrders
};

export default OrderService;