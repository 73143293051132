import http from '../httpConfig';

const getProxies = ({token, order, orderBy, page, perPage, id, searchText, showDeleted, needPopulation}) => {
  let url = `/proxy?searchText=${searchText}&showDeleted=${showDeleted || false}&needPopulation=${needPopulation || false}`;

  if(order && orderBy && page && perPage) {
    url += `&order=${order}&orderBy=${orderBy}&page=${page}&perPage=${perPage}&id=${id || ""}`
  }
  return http.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const addProxy = (token, payload) => {
  return http.post('/proxy', payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const editProxy = (token, payload) => {
  return http.put('/proxy', payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const deleteProxy = (token, proxyId) => {
  return http.delete(`/proxy/${proxyId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const ProxyService = {
  getProxies,
  addProxy,
  editProxy,
  deleteProxy
};

export default ProxyService;