import React from 'react';
import {
  BarChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import moment from "moment";
import { CHART_TOOLTIP_MAPPING } from "../../utils/appConstants"

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const payloadData = payload[0]?.payload ?? {};
    const title = moment(payloadData.date).format("MM/DD/YY HH:mm:ss")
    
    const tooltipData = payloadData?.tooltipData ?? {};

    const barDataContent = Object.keys(tooltipData).map((item, index) => {
      return (
        <div className="chart-content-tooltip-content-row" key={index}>
          <span className="chart-content-tooltip-content-row-key">{CHART_TOOLTIP_MAPPING[item]}</span>
          <span className="chart-content-tooltip-content-row-value">{tooltipData[item] || '-'}</span>
        </div>
      );
    });

    return (
      <div className="tooltip chart-content-tooltip-main">
        <div className="chart-content-tooltip-title">
          <label>{title}</label>
        </div>
        <div className="chart-content-tooltip-content">
          {barDataContent}
        </div>
      </div>
    );
  }

  return null;
};

const StackedBarChart = ({ bars, data, labels }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={330}
        data={data}
        margin={{
          top: 30,
          right: 20,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke={'#999999'} vertical={false} />
        <XAxis
          dataKey="dataKey"
          label={{ value: labels.xaxis, fontWeight: 700, fill: '#953A0B', fontSize: 14 }}
          tick={{
            fontWeight: 700, fill: '#999999', fontSize: 12, width: 20, height: 'auto'
          }}
          dy={5}
          tickLine={true}
          interval={0}
        />
        <YAxis
          axisLine={false}
          tick={{ fontWeight: 700, fill: '#999999', fontSize: 12 }}
          tickLine={false}
          label={{ value: labels.yaxis, transform: "rotate(90, 40, 150)", fontWeight: 700, fill: '#953A0B', fontSize: 14 }}
          allowDecimals={false}
        />
        <Tooltip wrapperClassName="tooltip" cursor={false} content={<CustomTooltip />} />
        {bars.map((bar) => bar)}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;